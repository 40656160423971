import React, { useRef, useEffect, useState } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from "react-router-dom";
import { addPost, getForum } from "../core/communities";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import ForumHeaderSection from "../components/forum/ForumHeaderSection";


const ForumPostForm = () => {
    const { comid, id } = useParams();
    const navigate = useNavigate();

    const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE;
    const editorRef = useRef(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [editorKey, setEditorKey] = useState(4);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
 
    const [forum, setForum] = useState(null);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [details, setDetails] = useState(`<p></p>`);
    const [breadcrumbPages, setBreadcrumbPages] = useState([]);

    useEffect(() => {
        const fetchForum = async () => {
            const data = await getForum(id);
            setForum(data);

            setBreadcrumbPages([
                { name: data?.community.title, href: `/`, current: false },
                { name: data?.title, href: `/community/${data?.community._id}/forum/${id}`, current: false },
                { name: "Add Post", href: ``, current: true },
            ]);
        }
        fetchForum();
    }, []);

    const clearForm = () => {
        setTitle("");
        setSubTitle("");
        setDetails("<p></p>");
        setEditorKey(editorKey*2);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const wordCount = editorRef.current.plugins.wordcount.getCount();
        if(wordCount === 0){
            setIsLoading(false);
            setErrors(["Please add post description"]);
            return false;
        }

        const inputs = {
            forumId: forum._id,
            title,
            subTitle,
            details
        };
        let response = null;
        response = await addPost(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('Post was submitted successfully', {
            icon: '✅'
        });

        navigate(`/community/${comid}/forum/${forum._id}`);
    }

    return (
        <div className="flex min-h-full flex-col bg-[#dae0e6]">
            <NavHeader currentPage={"addpost"} />
            <div className="mt-[50px]">
                <ForumHeaderSection
                    forum={forum}
                    breadcrumbPages={breadcrumbPages}
                />
            </div>
            <div className=" mx-auto flex flex-col md:flex-row w-full items-start  max-w-6xl gap-x-5 px-[5px] md:px-4 py-[10px] md:py-4">
                <AsideLeft shows={'leaderboard'} />

                <main className="flex-1 w-full">

                    {/* <ForumHeroSection forum={forum} /> */}

                    <div className="flex-grow flex flex-col  ">
                        
                        <form action="#" method="POST" onSubmit={handleSubmit}  className="bg-primary-white rounded-md drop-shadow-sm mb-20 md:mb-0">
                            <div className="space-y-12">
                                <div className="border border-gray-900/10 rounded-md ">
                                    <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">New Post</h2>
                                    <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                        Fill out and submit this form to add a new post to the forum.
                                    </p>

                                    <div className="bg-white mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">
                                        {/* title */}
                                        <div className="col-span-full">
                                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Title</b>
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    autoComplete="title"
                                                    value={title}
                                                    disabled={isLoading}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                        {/* sub title */}
                                        {/* <div className="col-span-full">
                                            <label htmlFor="sub-title" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Sub Title</b>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="sub-title"
                                                    id="sub-title"
                                                    value={subTitle}
                                                    disabled={isLoading}
                                                    onChange={(e) => setSubTitle(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div> */}
                                        {/* description */}
                                        <div className="col-span-full">
                                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Description</b>
                                                <span className="text-red-900 mx-1">*</span>
                                                {/* <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                                                    - Provide details of your post
                                                </span> */}
                                            </label>
                                            <div className="mt-2">
                                                <Editor
                                                    key={'editor1-'+editorKey}
                                                    apiKey={TINY_MCE_API_KEY}
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                    onEditorChange={(text) => setDetails(text)}
                                                    value={details}
                                                    init={{
                                                        height: 250,
                                                        menubar: false,
                                                        plugins: 
                                                            'advlist autolink lists link image  anchor ' +
                                                            'searchreplace visualblocks ' +
                                                            'media table past wordcount'
                                                        ,
                                                        toolbar: 'undo redo | formatselect | ' +
                                                            'bold italic backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | outdent indent | ' +
                                                            'bullist numlist',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-center md:justify-end ">

                                        <button role="button" 
                                            type="submit"
                                            disabled={isLoading}
                                            className="w-full md:w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit
                                        </button>

                                    </div>

                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                            {errors[0]}
                                        </div>
                                    }

                                </div>
                            </div>
                        </form>

                    </div>
                </main>
                <AsideRight />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default ForumPostForm;
