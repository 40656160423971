import React, { Fragment, useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { addSupportTicket, getSupportTicketDetails, respondToSupportTicket } from "../core/support";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import { useParams } from "react-router-dom";

const TicketDetails = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const [isAcceptRequestModalOpen, setIsAcceptRequestModalOpen] = useState(false);
    const toggleAcceptRequestModal = () => {
        setIsAcceptRequestModalOpen(!isAcceptRequestModalOpen);
    };

    const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState(false);
    const toggleRejectRequestModal = () => {
        setIsRejectRequestModalOpen(!isRejectRequestModalOpen);
    };

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [ticket, setTicket] = useState(null);


    const [details, setDetails] = useState("");
    const [isResolved, setIsResolved] = useState(false);
    const [attachment, setAttachment] = useState("");

    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";


    const clearForm = () => {
        setDetails("");
        setIsResolved(false);
        setAttachment("");
    };

    const handleOnFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setAttachment(onLoadEvent.target.result);
            //https://www.youtube.com/watch?v=7lhUsK-FxYI
            // const form = e.currentTarget
            // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setAttachment(data.secure_url);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            ticketId: ticket._id,
            details: details,
            attachment: attachment,
            isResolved: isResolved
        };
        let response = null;
        //normal post
        response = await respondToSupportTicket(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('Response to support ticket was posted successfully', {
            icon: '✅'
        });
        fetchSupportTicket();
    }

    const fetchSupportTicket = async () => {
        setIsLoading(true);
        const ticket = await getSupportTicketDetails(id);
        setTicket(ticket);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchSupportTicket();
    }, []);


    return (
        <div className="flex min-h-full flex-col bg-[#dae0e6]">
            <NavHeader currentPage={"support"} />

            <div className="mx-auto flex flex-col md:flex-row w-full items-start mt-[24px] max-w-6xl gap-x-5 px-[5px] md:px-4 py-[30px] md:py-10 ">
                <AsideLeft shows={'leaderboard'} />
                <main className="flex-1">
                    {ticket &&
                        <div className="flex-grow flex flex-col  ">
                            <div className="bg-white rounded-md">

                                <div className="bg-primary-white rounded-t-md border-b border-gray-200 flex flex-col md:flex-row justify-center p-3  md:items-center w-full ">
                                    <div className="text-gray-900 text-center text-md font-bold ">
                                        Support Ticket Details
                                    </div>
                                </div>

                                <ul role="list" className="divide-y divide-gray-100  mb-20 md:mb-0">
                                    <li className="flex last:rounded-b-md   ">
                                        <div className="flex flex-row w-full ">
                                            <div className="flex-auto flex-grow ">
                                                <div className="flex flex-col items-baseline w-full gap-x-4  p-3 bg-purple-50">


                                                    <p className="text-lg flex flex-col md:flex-row w-full md:items-center justify-between font-semibold leading-6 text-gray-900 ">
                                                        <span className="text-gray-400 capitalize text-xxs ">#{ticket._id}</span>



                                                        <div className="flex items-center gap-x-2">

                                                            <div className="flex flex-row">
                                                                <span className="text-gray-400 capitalize text-xxs">{ticket.category}</span>
                                                            </div>

                                                            {ticket.status === "1new" &&
                                                                <span className="text-xxs font-bold  text-red-700">Waiting Admin Response</span>
                                                            }
                                                            {ticket.status === "2user" &&
                                                                <span className="text-xxs font-bold  text-orange-700">Waiting Admin Response</span>
                                                            }
                                                            {ticket.status === "3admin" &&
                                                                <span className="text-xxs font-bold  text-purple-700">Awaiting Your Response</span>
                                                            }
                                                            {ticket.status === "4resolved" &&
                                                                <span className="text-xxs font-bold  text-green-700">Resolved</span>
                                                            }


                                                        </div>


                                                    </p>

                                                    <p className="py-1 flex flex-col md:flex-row w-full md:items-center justify-between leading-6 text-gray-900 mb-1 ">

                                                        <div className="flex text-xs gap-x-1 text-gray-500 flex-row">
                                                            <span>Request by:</span> <b >{ticket.user.username}</b>
                                                        </div>

                                                        <span className="text-xs font-bold  text-gray-400">
                                                            <ReactTimeAgo date={ticket.createdAt} locale="en-US" />
                                                        </span>

                                                    </p>

                                                    <div className="flex border-t border-b py-2 flex-col md:flex-row md:items-center w-full justify-between">
                                                        <p className="text-sm font-semibold leading-6  text-gray-700 capitalize">{ticket.title}</p>
                                                    </div>
                                                </div>
                                                <p className="w-full text-xs leading-5 text-gray-600 pt-2 p-3 bg-white">{ticket.details}</p>
                                                {ticket.attachment && ticket.attachment.length > 0 &&
                                                    <p className="w-full text-md  p-2 bg-white">
                                                        <img src={ticket.attachment} />
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    </li>

                                </ul>

                                <ul role="list" className="divide-y divide-gray-100 bg-gray-50 mb-20 md:mb-0">
                                    <li className="flex last:rounded-b-md   ">
                                        <div className="flex flex-col w-full p-2 ">
                                            {ticket.chats?.map((chat) => (
                                                <div className={`max-w-[70%] ${(chat.author === "admin") ? "bg-purple-200 text" : "self-end bg-green-200"} rounded border border-gray-200 mt-2 `}>
                                                    <div className="flex flex-col items-baseline w-full gap-x-4  p-3 ">
                                                        <p className="text-lg flex flex-col md:flex-row w-full md:items-center justify-between font-semibold leading-6 text-gray-900 mb-1 ">

                                                            <div className="flex flex-row">
                                                                <span className={` ${(chat.author === "admin") ? "text-purple-700" : "text-green-700"} capitalize text-xs`}>
                                                                    {(chat.author === "admin") ? "Admin" : "Replied With"}
                                                                </span>
                                                            </div>

                                                            <span className={` ${(chat.author === "admin") ? "text-purple-700" : "text-green-700"} text-xs font-bold `} >
                                                                <ReactTimeAgo date={chat.date} locale="en-US" />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <p className="w-full text-xs leading-5 text-gray-700 pt-2 p-3 bg-white">
                                                        {chat.details}
                                                    </p>
                                                    {chat.attachment && chat.attachment.length > 0 &&
                                                        <p className="w-full text-md  p-2 bg-white">
                                                            <img src={chat.attachment} />
                                                        </p>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md drop-shadow-md mt-5">
                                <div className="space-y-12">
                                    <div className="border border-gray-900/10 rounded-md ">
                                        <h2 className="text-sm font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">Send Response</h2>
                                        <p className=" text-xs text-gray-600 px-4 pt-2 pt-0">
                                            Fill out this form to make a response
                                        </p>

                                        <div className="mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">
                                            {/* is resolved */}
                                            {loggedInUser && loggedInUser.role === "admin" &&
                                                <div className="col-span-full">
                                                    <div className="mb-2 flex h-6 items-center">
                                                        <input
                                                            id="is_resolved-checkbox"
                                                            name="isResolved"
                                                            type="checkbox"
                                                            className="h-4 w-4 mb-0 rounded border-purple-900 text-purple-900 focus:ring-0 "
                                                            disabled={isLoading}
                                                            checked={isResolved}
                                                            onChange={(e) => setIsResolved(!isResolved)}
                                                        />

                                                        <div className="app-sm-fs ml-3 text-sm leading-6 nowrap cursor-pointer">
                                                            <label htmlFor="is_resolved-checkbox" className="font-medium text-xs  cursor-pointer text-gray-900">
                                                                Mark this issue as resolved
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* details */}
                                            <div className="col-span-full">
                                                <div className="mt-2">
                                                    <textarea
                                                        id="about"
                                                        name="about"
                                                        rows={4}
                                                        required
                                                        value={details}
                                                        disabled={isLoading}
                                                        onChange={(e) => setDetails(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    />
                                                </div>
                                            </div>



                                            {/* attachment */}
                                            <div className="col-span-full pt-5 flex flex-col md:flex-row items-center  gap-x-3 ">

                                                <div className="pt-x flex flex-col bg-gray-100">
                                                    <img
                                                        className="inline-block h-[200px] min-w-[250px] rounded-sm"
                                                        src={attachment}
                                                        alt=""
                                                    />
                                                </div>

                                                <div className="pt-2 flex flex-col md:items-start justify-center  md:justify-end w-full flex-grow self-stretch  ">
                                                    <label htmlFor="photo" className=" block text-sm  text-gray-900 w-full text-center md:text-start ">
                                                        Image Attachment <small>(optional)</small>
                                                    </label>

                                                    <div className="flex items-center justify-center bg-grey-lighter mt-2">
                                                        <label disabled={isLoading} htmlFor="select-attachment" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                            <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                            </svg>
                                                            <span className="text-xs  leading-6 text-gray-900 ">
                                                                {(!attachment || attachment.length === 0) ? 'Select' : 'Change'} Photo or Screenshot
                                                            </span>
                                                            <input id="select-attachment" disabled={isLoading} name="select-attachment" type='file' className="hidden" onChange={handleOnFileChange} />
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-end ">
                                            <button role="button" 
                                                type="submit"
                                                disabled={isLoading}
                                                className="w-full md:w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                            >
                                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                Submit
                                            </button>
                                        </div>

                                        {errors.length > 0 &&
                                            <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                                {errors[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>

                        </div>
                    }
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default TicketDetails;
