import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import { getJobListing } from "../core/job_listing";
import JobsSkeleton from "../components/skeletons/JobsSkeleton"
import { Dialog } from '@headlessui/react'
// import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

export function HeroSection() {

    const navigation = [
        { name: 'Product', href: '#' },
        { name: 'Features', href: '#' },
        { name: 'Marketplace', href: '#' },
        { name: 'Company', href: '#' },
    ]

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="bg-primary-white rounded-t-lg ">
            <main>
                <div className="relative isolate ">
                    <svg
                        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
                    </svg>
                    <div
                        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                        />
                    </div>
                    <div className="overflow-hidden ">
                        <div className="mx-auto max-w-7xl md:px-6 pb-8 pt-2 sm:pt-60 lg:px-8 lg:pt-8 ">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-start ">
                                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl p-3 md:p-0 ">
                                    <h1 className="sm:text-5xl font-bold tracking-tight text-gray-900 ">
                                        <strong>We're changing the way people connect.</strong>
                                    </h1>
                                    <div className="pt-5 aspect-video">
                                        {/* width="640" height="360"  */}
                                        <iframe
                                            title="This video introduces the wefayo platform, it talks about what wefayo is all about etc"
                                            src="https://player.cloudinary.com/embed/?public_id=Wefayo_Founders_Video_Inside_fjopzo&cloud_name=nyolacloudinary&player[showLogo]=false&source[poster]=https%3A%2F%2Fres.cloudinary.com%2Fnyolacloudinary%2Fimage%2Fupload%2FScreenshot_2024-08-16_at_06.39.49_i5giku.jpg"
                                            className="w-full h-full"
                                            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                                            allowfullscreen
                                            frameborder="0"
                                        ></iframe>

                                        {/* https://vizard.ai/editor?id=5591504 for the transcribing */}
                                        <div class="sr-only">
                                            <p>Hi, I'm Kevin Field, and I'm Henry Luja.</p>
                                            <p>We're the founders of Wefayo, which means, we care.</p>
                                            <p>We are delighted that you're here.</p>
                                            <p>Wefayo is a place where you can connect with new friends, access reliable information, find jobs, and enjoy many more features we're developing, all free.</p>
                                            <p>It's a community that cares about bringing together people with different backgrounds and abilities, fostering everlasting friendships, laughter, and opportunity.</p>
                                            <p>Whether you need a friend to chat with, a reliable source of information, a job that respects your abilities, or anything else we can offer, we're here for you.</p>
                                            <p>Please explore this site and make yourself at home.</p>
                                            <p>If there's a medical condition or special need you'd like added, just let us know, and we'll do our best to accommodate it.</p>
                                            <p>Thanks for being part of Wefayo.</p>
                                        </div>
                                    </div>
                                    <p className="relative mt-5 text-sm leading-6 text-gray-600 sm:max-w-md lg:max-w-none">
                                        Wefayo is a community that brings together people from different backgrounds, where you can connect with new friends, share your unique experiences, find reliable information, and discover features we’re developing for you – all for free.
                                    </p>
                                    <p className="relative mt-5 text-sm leading-6 text-gray-600 sm:max-w-md lg:max-w-none">
                                        You are not alone. You have a community that supports and values you. Your voice matters, and you have a purpose that inspires you. Whether you need a friend to chat with, reliable information, a job that respects your abilities, or anything else we can offer, we’re here for you.
                                    </p>
                                    <p className="relative mt-5 text-sm leading-6 text-gray-600 sm:max-w-md lg:max-w-none">
                                        Please feel free to explore the site and make yourself at home. If you want a medical condition or special need added, just let us know, and we’ll do our best to accommodate it.
                                    </p>
                                    <p className="relative mt-5 text-sm leading-6 text-gray-600 sm:max-w-md lg:max-w-none">
                                        Thank you for being part of Wefayo.
                                    </p>

                                    <div className="mt-10 flex items-center gap-x-6">
                                        <a
                                            href="/jobs"
                                            className="rounded-md bg-purple-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Get Your Job
                                        </a>
                                        <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
                                            Explore Communities <span aria-hidden="true">→</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-14 flex justify-end gap-1 md:gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                    <div className="hidden md:block ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                        <div className="relative">
                                            <img
                                                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                                                alt="Image: Wefayo community sharing"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36 pl-3 md:pl-0 ">
                                        <div className="relative">
                                            <img
                                                src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                                                alt="Image: Wefayo community sharing"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative">
                                            <img
                                                src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                                                alt="Image: Wefayo community sharing"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0  pr-3 md:pr-0">
                                        <div className="relative">
                                            <img
                                                src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                                                alt="Image: Wefayo community sharing"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative">
                                            <img
                                                src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                                                alt="Image: Wefayo community sharing"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export function TeamSection() {
    const people = [
        {
            name: 'Leslie Alexander',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Leslie Alexander',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Leslie Alexander',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Leslie Alexander',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Leslie Alexander',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Leslie Alexander',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        // More people...
    ]
    return (
        <div className="bg-white py-8 sm:py-16">
            <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                <div className="max-w-2xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
                        suspendisse.
                    </p>
                </div>
                <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                    {people.map((person) => (
                        <li key={person.name}>
                            <div className="flex items-center gap-x-6">
                                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                                <div>
                                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                                    <p className="text-sm font-semibold leading-6 text-purple-900">{person.role}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export function ContentSection() {
    return (
        <div className="bg-primary-white py-8 sm:py-14">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <p className="text-base font-semibold leading-7 text-purple-900">Feel At Home</p>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better community</h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div>
                            <p>
                                Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
                                vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque
                                erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris
                                semper sed amet vitae sed turpis id.
                            </p>
                            <p className="mt-8">
                                Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor
                                fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac
                                adipiscing egestas.
                            </p>
                        </div>
                        <div>
                            <p>
                                Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim
                                eget. Est augue maecenas risus nulla ultrices congue nunc tortor.
                            </p>
                            <p className="mt-8">
                                Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor
                                fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac
                                adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export function ContactUsSection() {
    /*
        This example requires some changes to your config:
        
        ```
        // tailwind.config.js
        module.exports = {
            // ...
            plugins: [
            // ...
            require('@tailwindcss/forms'),
            ],
        }
        ```
    */
    return (
        <div className="relative isolate bg-white w-full rounded-b-lg">
            <div className=" grid  grid-cols-1 lg:grid-cols-2x rounded-b-lg overflow-hidden">
                <div className="relative bg-red-400x flex justify-center px-6 pb-8 pt-8 sm:pt-16 lg:static lg:px-8 lg:py-8 rounded-b-lg">
                    <div className="flex  flex-col justify-center mx-auto  bg-green-400x lg:mx-0 rounded-b-lg">
                        <div className="  absolute rounded-bl-lg inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 xring-1 ring-gray-900/10 ">
                            <svg
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        x="100%"
                                        y={-1}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M130 200V.5M.5 .5H200" fill="none" />
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                                </svg>
                                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
                            </svg>
                        </div>
                        <h2 className="text-md font-bold tracking-tight text-gray-900 text-center">Get in touch</h2>
                        {/* <p className="w-full  md:w-1/2 m-auto text-lg leading-8 text-gray-600 rounded-b-lg text-center">
                            Proin volutpat consequat porttitor cras nullam gravida at. Orci molestie a eu arcu. Sed ut tincidunt
                            integer elementum id sem. Arcu sed malesuada et magna.
                        </p> */}
                        <p className="w-full   m-auto text-lg leading-8 text-gray-600 rounded-b-lg text-center py-4">

                            <a href="mailto:info@wefayo.com" className="flex flex-row items-center gap-x-1 text-md">
                                <span>
                                    <EnvelopeIcon className="h-7 w-6" aria-hidden="true" />
                                </span>
                                <span>
                                    info@wefayo.com
                                </span>
                            </a>
                        </p>
                        {/* <dl className="md:max-w-[50%] flex flex-col gap-y-5 md:flex-row items-start self-center gap-x-5  mt-10  leading-7 text-gray-600 rounded-b-lg ">
                            <div className="flex justify-center gap-x-1  text-center ">
                                <dt className="">
                                    <span className="sr-only">Address</span>
                                    <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                </dt>
                                <dd>
                                    545 Mavis Island
                                    <br />
                                    Chicago, IL 99191
                                </dd>
                            </div>
                            <div className="flex justify-center gap-x-1 ">
                                <dt className="flex-none">
                                    <span className="sr-only">Telephone</span>
                                    <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                </dt>
                                <dd>
                                    <a className="hover:text-gray-900" href="tel:+1 (555) 234-5678">
                                        +1 (555) 234-5678
                                    </a>
                                </dd>
                            </div>
                            <div className="flex justify-center gap-x-1">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                </dt>
                                <dd>
                                    <a className="hover:text-gray-900" href="mailto:hello@example.com">
                                        hello@example.com
                                    </a>
                                </dd>
                            </div>
                        </dl> */}
                    </div>
                </div>
                {/* <form action="#" method="POST" className="px-6 pb-8 pt-8 sm:pb-16 lg:px-8 lg:py-8">

                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg ">
                        <div className="text-sm all-required mb-5">
                            All fields are required.
                        </div>
                        <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                    First name
                                    <span className="text-red-900 mx-1">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Last name
                                    <span className="text-red-900 mx-1">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Email
                                    <span className="text-red-900 mx-1">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Phone number
                                    <span className="text-red-900 mx-1">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="tel"
                                        name="phone-number"
                                        id="phone-number"
                                        autoComplete="tel"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Message
                                    <span className="text-red-900 mx-1">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows={4}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button role="button" 
                                type="submit"
                                className="rounded-md bg-purple-900 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Send message
                            </button>
                        </div>
                    </div>
                </form> */}
            </div>
        </div>
    )
}

const About = () => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {



    }, []);


    return (
        <div className="flex min-h-full flex-col bg-primary-gray  ">
            <NavHeader currentPage={"about"} />
            <div className="mx-auto flex flex-col w-full items-start mt-[24px] max-w-7xl px-4 py-10 sm:px-6 lg:px-8">

                <HeroSection></HeroSection>

                {/* <TeamSection></TeamSection> */}
                {/* <ContentSection></ContentSection> */}
                <ContactUsSection></ContactUsSection>



            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default About;

