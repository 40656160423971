import React, { useState, useEffect } from "react";
import { getActivityboard } from "../../core/invite";
import NavUserAvatar from "../navigation/NavUserAvatar";
import LeaderBoardSkeleton from "../skeletons/LeaderBoardSkeleton";
import { useNavigate } from "react-router-dom";
import LoginAlertModal from "../auth/LoginAlertModal";
import { Utils } from "../../utils";
import Star  from "../icons/Star";

const ActivitiesBoard = ({mode="aside"}) => {

    const navigate = useNavigate();

    const [activityboard, setActivityboard] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        const fetchActivityboard = async () => {
            setIsLoading(true);
            const data = await getActivityboard();
            setActivityboard(data);
            setIsLoading(false);
        }

        fetchActivityboard();

    }, []);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const goToProfile = (e, user) => {
        e.preventDefault();
        console.log("here");
        if (loggedInUser) {
            navigate(`/profile/${user._id}`);
        } else {
            toggleLoginAlertModalModal()
        }
    };

    return (
        <div className={`${mode==='page'?'mt-2 bg-white rounded-md':''} flex flex-col h-fit md:h-[576px] w-full `}>
            {isLoading && <LeaderBoardSkeleton />}
            {activityboard.length > 0 &&
                <>
                    <div className="text-center font-bold py-2 border-b rounded-md border-gray-200 md:border-0 bg-primary-white">
                        Activity Leaders
                    </div>

                    <div

                        className={`flex ${mode==='page'?'flex-col rounded-b-md':'md:block'} overflow-auto  md:divide-y divide-primary-gray-100  shadow-sm sm:ring-0 md:ring-1 ring-gray-900/5 sm:rounded-sm`}
                    >
                        {activityboard.map((user, index) => (
                            <div key={index} className={`${mode==='page'?'p-3':'p-2 md:min-w-[120px] mr-2 '} even:bg-white odd:bg-slate-100 relative flex flex-row gap-x-2  md:mr-0 justify-start hover:bg-primary-gray-50  `}>

                                <NavUserAvatar user={user} link={"#"} imgSizeClass={`${mode==='page'?'w-[50px] h-[50px] max-w-[50px] max-h-[50px]':'w-[48px] h-[48px] max-w-[48px] max-h-[48px]'} block`} />

                                <div className={`flex flex-col justify-between flex-grow min-w-[100px] md:min-w-none`}>
                                    <div className={`${mode==='page'?'text-md':'text-xs'} font-semibold leading-6x text-primary-gray-900`}>
                                        <div onClick={(e) => goToProfile(e, user)} className="whitespace-nowrap cursor-pointer">
                                            <div className="flex flex-row items-center justify-between ">
                                                <div className={`${mode==='page'?'':' max-w-[100px]'}`} style={{whiteSpace: "normal"}}>{user.username}</div>
                                                <div className="relative flex items-center justify-center self-start bg-purple-200 rounded-lg" style={{heightx: "30px", minWidth: "30px"}}>
                                                    <div className="absolute" >
                                                        {/* <Star  /> */}
                                                    </div>
                                                    <div className="text-purple-900 " style={{fontSize: "10px"}}>
                                                        {Utils.bigNumberFormat(user.statistics?.total_points,1)}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="absolute inset-x-2 top-[4px] bottom-0 font-bold w-fit" >
                                                {index+1}
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-x-2"> 
                                        <span className={`${mode==='page'?'text-sm':'text-xs'} capitalize font-semibold`}>
                                            <span className={`${mode==='page'?'text-sm':'text-xxs'} text-gray-700  font-light`}>
                                                {Utils.bigNumberFormat(user.statistics?.create_forum_post, 1)} Post
                                                {(user.statistics?.create_forum_post??0)==1?'':'s'}
                                            </span>
                                        </span>
                                        <span className={`${mode==='page'?'text-sm':'text-xs'} capitalize font-semibold`}>
                                            <span className={`${mode==='page'?'text-sm':'text-xxs'} text-gray-700 font-light`}>
                                                {Utils.bigNumberFormat(user.statistics?.post_reply, 1)} Comment
                                                {(user.statistics?.post_reply??0)==1?'':'s'}
                                            </span>
                                        </span>
                                    </div>

                                    <div className="flex items-center">
                                        <span className={`${mode==='page'?'text-sm':'text-xs'} capitalize font-semibold`}>
                                            <span className={`${mode==='page'?'text-sm':'text-xxs'} text-gray-700 font-light`}>
                                                {Utils.bigNumberFormat((user.statistics?.like_post??0)+(user.statistics?.like_reply??0), 1)} Liked
                                            </span>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
            <LoginAlertModal isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
        </div>
    );
};

export default ActivitiesBoard;