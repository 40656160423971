export const inviteRules = ({email, name, message}) => {
    return {
        email: {
            errors: [],
            value: email,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 60,
                    error: "Email address: cannot be more than 60 characters",
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: At least 5 characters are required",
                // },
                emailFormat: "Email address: Invalid email address",
            },
        },
        name: {
            errors: [],
            value: name,
            isValid: null,
            validations: {
                required: "Name is required",
                maxLength: {
                    value: 30,
                    error: "Name: cannot be more than 30 characters"
                },
                // minLength: {
                //     value: 3,
                //     error: "Name: at least 3 characters are required"
                // }
            }
        },
        message: {
            errors: [],
            value: message,
            isValid: null,
            validations: {
                maxLength: {
                    value: 200,
                    error: "Message: cannot be more than 200 characters"
                }
            }
        }
    }
}