import React, { Fragment, useEffect, useRef, useState } from "react";

const CommunityAccordionSkeleton = ({items=3}) => {
    return (
        <div role="status" className="flex flex-col mb-2 w-full animate-pulse bg-gray-100 mb-5">
            <div className="flex flex-row w-full p-2 gap-x-2 border-b border-gray-200 ">
                <div className="h-5 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
                <div className="h-5 w-1/3 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
            </div>
            {[...Array(items)].map((e, i) => (
                <div key={i} className="flex flex-row w-full p-2 gap-x-2 border-b border-gray-200">
                    <div className="h-16 w-16 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="flex flex-row flex-1 justify-between ">
                        <div className="flex flex-col justify-between ">
                            <div className="h-5 w-48 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="flex flex-row gap-x-4">
                                <div className="h-5 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                                <div className="h-5 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center ">
                            <div className="h-5 w-48 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="flex flex-row gap-x-4 pt-1">
                                <div className="h-5 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                                <div className="h-5 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default CommunityAccordionSkeleton;

