import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import { getJobListing } from "../core/job_listing";
import { getJobsBudgesLastTime, updateJobsBudgesLastTime} from "../core/communities";
import JobsSkeleton from "../components/skeletons/JobsSkeleton";
import { getSessionData } from "../utils/auth";
import { Utils } from "../utils";

const Jobs = () => {

    const [jobListing, setJobListing] = useState(null);
    const [lastJobBudgeDate, setLastJobBudgeDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);

    let loggedInUser = getSessionData();

    useEffect(() => {

        const fetchJobs = async () => {
            setIsLoading(true)

            if (loggedInUser !== null) {
                const lastJobsBudgesDateTime = await getJobsBudgesLastTime();
                let dateStr = lastJobsBudgesDateTime?.last_budges_jobs_date_time??'';
                dateStr = dateStr.trim();
                let fromJobsDateTime = new Date();
                if (dateStr.length > 0) {
                    fromJobsDateTime = new Date(dateStr);
                } else {
                    const dateStrLocal = localStorage.getItem("last_budges_jobs_date_time");
                    fromJobsDateTime = (dateStrLocal) ? new Date(dateStrLocal) : Utils.getLastWeeksDate();
                }
                setLastJobBudgeDate(fromJobsDateTime);
            }else{
                //get from localhost
                const dateStrLocal = localStorage.getItem("last_budges_jobs_date_time");
                if(dateStrLocal){
                    const fromJobsDateTime = new Date(dateStrLocal);
                    setLastJobBudgeDate(fromJobsDateTime);
                }else{
                    const fromJobsDateTime = Utils.getLastWeeksDate();
                    setLastJobBudgeDate(fromJobsDateTime);
                }
            }

            const jobListingCommunity = await getJobListing();
            setJobListing(jobListingCommunity);
            setIsLoading(false)

            //update the last jobs budge date
            if (loggedInUser !== null) {
                updateJobsBudgesLastTime();
            }else{
                const dateStrLocal = (new Date()).toISOString();
                localStorage.setItem("last_budges_jobs_date_time",dateStrLocal);
            }
        }
        fetchJobs();
    }, []);


    return (
        <div className="flex min-h-full flex-col bg-primary-gray">
            <NavHeader currentPage={"jobs"} />

            <div className=" mx-auto flex flex-col md:flex-row w-full items-start mt-[24px] max-w-6xl gap-x-5 px-[5px] md:px-4 py-[30px] md:py-10 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 w-full">
                    {isLoading &&
                        <JobsSkeleton />
                    }
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col drop-shadow-sm">
                            {jobListing &&
                                <JobListingCommunityAccordion
                                    community={jobListing}
                                    isOpenInitially={true}
                                    key={'jobslisting-community'}
                                    lastJobBudgeDate={lastJobBudgeDate}
                                />
                            }
                        </div>
                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default Jobs;