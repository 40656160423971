import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { addForumToCommunity, getCommunity } from "../core/communities";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AddCommunityForumForm = () => {
    const { comid } = useParams();
    const navigate = useNavigate();

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [community, setCommunity] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [order, setOrder] = useState("10");
    const [status, setStatus] = useState("active");

    const clearForm = () => {
        setTitle("");
        setDescription("");
        setOrder("10");
        setStatus("active");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: community._id,
            title,
            description,
            order,
            status
        };
        let response = null;
        //normal post
        response = await addForumToCommunity(inputs);
        setIsLoading(false);
        if (Array.isArray(response) || (Array.isArray(response) && response.length > 0 && typeof response[0] === 'string')) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The forumm was added successfully', {
            icon: '✅'
        });

        for (let index = 0; index < response.forums.length; index++) {
            const forum = response.forums[index];
            if (forum.title === inputs.title) {
                navigate(`/community/${comid}/forum/${forum._id}`);
                break;
            }
        }
    }

    const fetchData = async () => {
        const results = await getCommunity(comid);
        setCommunity(results);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="flex min-h-full flex-col ">
            <NavHeader currentPage={"edit-community"} />

            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px] gap-x-5 px-[5px] md:px-4 py-[30px] md:py-10 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 flex-grow self-stretch flex ">
                    <div className=" flex-grow self-stretch flex flex-col flex-1 ">
                        {community &&
                            <div className="flex rounded-md border-t-2 border-t-purple-400 bg-purple-900 p-3 mb-3 w-full">
                                <div className="mt-1 mb-2 flex flex-row">
                                    <div className="w-[48px] h-[48px] bg-purple-800 flex justify-center items-center text-purple-300 font-bold">
                                        <img
                                            className="h-10 w-auto"
                                            src="/logo.png"
                                            alt="wefayo"
                                        />
                                    </div>
                                    <div className="pl-2 flex flex-col">
                                        <div className="text-left text-white text-lg font-bold">
                                            {community.title}
                                        </div>
                                        {community.description && community.description.length > 0 &&
                                            <div className="text-left text-purple-100 text-md font-normal">
                                                {community.description}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md  flex flex-1">

                            <div className="space-y-12 flex-1 flex">
                                <div className="flex-1 flex flex-col border border-gray-900/10 ">
                                    <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0 flex justify-between">
                                        Add Forum To Community Form
                                    </h2>
                                    <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                        Fill out this form and submit it to add a forum to the community.
                                    </p>

                                    <div className="flex-1 flex flex-col justify-start items-start mt-2 py-5 px-4 gap-x-6 gap-y-4 border-t border-purple-900/10 bg-white">
                                        {/* title */}
                                        <div className="w-full">
                                            <label htmlFor="com-title" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                Forum Title
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="com-title"
                                                    required
                                                    value={title}
                                                    disabled={isLoading}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    className="app-sm-md-h block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-row items-center gap-x-4">
                                            {/* order */}
                                            <div className="w-1/2">
                                                <label htmlFor="display-order" className="block text-sm font-medium leading-6 text-gray-900">

                                                    Display Order
                                                    <span className="text-red-900 mx-1">*</span>

                                                </label>
                                                <div className="">
                                                    <input
                                                        type="number"
                                                        id="display-order"
                                                        name="order"
                                                        className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        required
                                                        value={order}
                                                        disabled={isLoading}
                                                        onChange={(e) => setOrder(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {/* status */}
                                            <div className="w-1/2">
                                                <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">

                                                    Status
                                                    <span className="text-red-900 mx-1">*</span>

                                                </label>
                                                <div className="">
                                                    <select
                                                        id="status"
                                                        name="status"
                                                        className=" block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        required
                                                        value={status}
                                                        disabled={isLoading}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    >
                                                        <option value="active">Active</option>
                                                        <option value="disabled">disabled</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {/* description */}
                                        <div className="w-full">
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Brief Description (optional)
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    rows={2}
                                                    value={description}
                                                    disabled={isLoading}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-between ">
                                        <a
                                            href="/"
                                            className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                        >
                                            Cancel
                                        </a>

                                        <button role="button" 
                                            type="submit"
                                            disabled={isLoading}
                                            className="app-sm-md-h app-sm-fs w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit
                                        </button>
                                    </div>

                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                            {errors[0]}
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default AddCommunityForumForm;
