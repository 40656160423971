import React, { Fragment, useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import labData from "../utils/labData";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Avatar from "../components/icons/Avatar";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import AcceptRequestModal from "../components/request/AcceptRequestModal";
import RejectRequestModal from "../components/request/RejectRequestModal";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { addCommunityRequest, getCommunityRequests, searchCommunityRequests } from "../core/communities";
import { addSupportTicket, getSupportTickets } from "../core/support";
import { useNavigate } from "react-router-dom";
import { Utils } from "../utils/index";
import ReactTimeAgo from 'react-time-ago';

const Support = () => {

  const requests = labData.communityRequests;

  const navigate = useNavigate();

  const [isAcceptRequestModalOpen, setIsAcceptRequestModalOpen] = useState(false);
  const toggleAcceptRequestModal = () => {
    setIsAcceptRequestModalOpen(!isAcceptRequestModalOpen);
  };

  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState(false);
  const toggleRejectRequestModal = () => {
    setIsRejectRequestModalOpen(!isRejectRequestModalOpen);
  };

  let loggedInUserStr = sessionStorage.getItem("user");
  let loggedInUser = null;
  if (loggedInUserStr) {
    loggedInUser = JSON.parse(loggedInUserStr);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [category, setCategory] = useState("");
  const [attachment, setAttachment] = useState("");
  const [formerTicketId, setFormerTicketId] = useState("");

  const [tickets, setTickets] = useState([]);

  const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
  const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";


  const clearForm = () => {
    setTitle("");
    setDetails("");
    setCategory("");
    setAttachment("");
    setFormerTicketId("");
  };

  const handleOnFileChange = async (changeEvent) => {
    const reader = new FileReader();
    reader.onload = async function (onLoadEvent) {
      setAttachment(onLoadEvent.target.result);
      //https://www.youtube.com/watch?v=7lhUsK-FxYI
      // const form = e.currentTarget
      // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
      const fileInput = changeEvent.target;
      const formData = new FormData();
      formData.append('file', fileInput.files[0]);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      const data = await fetch(CLOUDINARY_URL, {
        method: "POST",
        body: formData
      })
        .then(r => r.json())
        .then((data) => {
          setAttachment(data.secure_url);
        });
    }
    if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
      reader.readAsDataURL(changeEvent.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setIsLoading(true);

    const inputs = {
      title,
      details,
      category,
      attachment,
      formerTicketId
    };
    let response = null;
    //normal post
    response = await addSupportTicket(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }
    clearForm();
    toast.success('Your Support Ticket Was Posted Successfully', {
      icon: '✅',
      position: "top-center"
    });
    fetchSupportTickets();
  }

  const fetchSupportTickets = async () => {
    setIsLoading(true);
    const tickets = await getSupportTickets();
    setTickets(tickets);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSupportTickets();
  }, []);


  const goToTicket = (e, ticket) => {
    e.preventDefault();
    navigate(`/support/${ticket._id}`);
  }

  const gotoReferencedId= (e, id) => {
    e.preventDefault();
    navigate(`/support/${id}`);
  }


  return (
    <div className="flex min-h-full flex-col bg-[#dae0e6]">
      <NavHeader currentPage={"support"} />
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: 'app-toastx',
          position: 'top-center'
          // style: {
          //   border: '1px solid #713200',
          //   padding: '16px',
          //   color: '#713200',
          // },
        }}
      />
      <div className="mx-auto flex flex-col md:flex-row w-full items-start mt-[24px] max-w-6xl gap-x-5 px-[5px] md:px-4 py-[30px] md:py-10 ">
        <AsideLeft shows={'leaderboard'} />
        <AsideRight className="md:hidden" />
        <main className="flex-1">
          <div className="flex-grow flex flex-col  ">
            {(loggedInUser?.role !== "admin") &&
              <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md drop-shadow-md">
                <div className="space-y-12">
                  <div className="border border-gray-900/10 rounded-md ">
                    <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">Support Ticket Form</h2>
                    <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                      Fill out this form to send a new support ticket.
                    </p>

                    <div className="mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">

                      


                      {/* title */}
                      <div className="col-span-full">
                        <label htmlFor="support-title" className="block text-sm font-medium leading-6 text-gray-900">
                          Subject
                          <span className="text-red-900 mx-1">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="support-title"
                            id="support-title"
                            autoComplete="subject"
                            required
                            value={title}
                            disabled={isLoading}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mb-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                          />
                        </div>
                      </div>

                      {/* category */}
                      <div className="col-span-full">
                        <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
                          Category
                          <span className="text-red-900 mx-1">*</span>
                        </label>
                        <div className="">
                          <select
                            id="category"
                            name="category"
                            className="mb-4  block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                            required
                            value={category}
                            disabled={isLoading}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value=""></option>
                            <option value="General Inquiry">General Inquiry</option>
                            <option value="Jobs">Jobs</option>
                            <option value="Invitations and Leader Board">Invitations and Leader Board</option>
                            <option value="Technical Assistance">Technical Assistance</option>
                            <option value="Support Follow Up">Support Follow Up (requires ticket number)</option>
                          </select>
                        </div>
                      </div>

                      {/* ticket number */}
                      {category === "Support Follow Up" &&
                      <div className="col-span-full">
                        <label htmlFor="support-formerTicketId" className="block text-sm font-medium leading-6 text-gray-900">
                          Ticket Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="support-formerTicketId"
                            id="support-formerTicketId"
                            required
                            value={formerTicketId}
                            disabled={isLoading}
                            onChange={(e) => setFormerTicketId(e.target.value)}
                            className="mb-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                          />
                        </div>
                      </div>
                      }

                      {/* details */}
                      <div className="col-span-full">
                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                          Description
                          <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                            - Provide details about the issue.
                          </span>
                        </label>
                        <div className="mt-2">
                          <textarea
                            id="about"
                            name="about"
                            rows={4}
                            required
                            value={details}
                            disabled={isLoading}
                            onChange={(e) => setDetails(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                          />
                        </div>
                      </div>

                      

                      {/* attachment */}
                      <div className="col-span-full pt-5 flex flex-col md:flex-row items-center  gap-x-3 ">

                        <div className="pt-x flex flex-col bg-gray-100 w-full md:w-auto">
                          <img
                            className="inline-block h-[200px] min-w-[100%] md:min-w-[250px] rounded-sm"
                            src={attachment}
                            alt=""
                          />
                        </div>

                        <div className="pt-2 flex flex-col flex-grow self-stretch justify-end items-start">
                          <label htmlFor="photo" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                            Image Attachment <small>(optional)</small>
                          </label>

                          <div className="flex items-center justify-center bg-grey-lighter mt-2">
                            <label disabled={isLoading} htmlFor="select-attachment" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                              <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                              </svg>
                              <span className="text-xs font-medium leading-6 text-gray-900 ">
                                {(!attachment || attachment.length === 0) ? 'Select' : 'Change'} photo or screen shoot
                              </span>
                              <input id="select-attachment" disabled={isLoading} name="select-attachment" type='file' className="hidden" onChange={handleOnFileChange} />
                            </label>
                          </div>
                        </div>

                      </div>

                    </div>

                    <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-end ">
                      <button role="button" 
                        type="submit"
                        disabled={isLoading}
                        className="w-full md:w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                      >
                        {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                        Submit
                      </button>
                    </div>

                    {errors.length > 0 &&
                      <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                        {errors[0]}
                      </div>
                    }
                  </div>
                </div>
              </form>
            } 

            <div className={`bg-white rounded-md ${(loggedInUser?.role !== "admin")?"mt-1 md:mt-10":""} `}>
              {tickets.length > 0 &&
                <div className="bg-primary-white rounded-t-md border-b border-gray-200 flex flex-col md:flex-row justify-between p-3  md:items-center w-full ">
                  <div className="text-gray-900 text-md font-bold ">
                    {loggedInUser.role !== "admin" ? "Your " : " "}Support Tickets
                  </div>
                </div>
              }
              <ul role="list" className="divide-y divide-gray-100  mb-20 md:mb-0">
                {tickets.map((ticket) => (
                  <li key={ticket._id} onClick={(e)=>goToTicket(e,ticket)} className={`flex last:rounded-b-md ${ticket.status === "3admin"?"bg-purple-200":"even:bg-white odd:bg-slate-50"}  cursor-pointer hover:bg-purple-50`}>
                    <div className="flex flex-row w-full p-3">
                      <div className="flex-auto flex-grow ml-3">
                        <div className="flex flex-col items-baseline w-full gap-x-4">
                          <p className="text-lg flex flex-col md:flex-row w-full md:items-center justify-between font-semibold leading-6 text-gray-900 mb-1 ">

                            <div className="flex flex-row">
                              
                              <span className="text-gray-400 capitalize text-xxs mr-4">#{ticket._id}</span>
                              
                              {(loggedInUser?.role === "admin") &&
                                <span className="text-gray-400 text-xxs">By {ticket.user.username}</span>
                              }
                            </div>

                            <div>

                              <span className="text-gray-400 capitalize text-xxs mr-4">{ticket.category}</span>
                              
                              
                              {ticket.status === "1new" &&
                                <span className="text-xxs font-bold  text-red-700">Waiting Admin Response</span>
                              }
                              {ticket.status === "2user" &&
                                <span className="text-xxs font-bold  text-orange-700">Waiting Admin Response</span>
                              }
                              {ticket.status === "3admin" &&
                                <span className="text-xxs font-bold  text-purple-700">Awaiting Your Response</span>
                              }
                              {ticket.status === "4resolved" &&
                                <span className="text-xxs font-bold  text-green-700">Resolved</span>
                              }

                              
                            </div>
                          </p>
                          <div className="flex flex-col md:flex-row md:items-center w-full justify-between">
                            <p className="text-sm font-semibold leading-6  text-gray-900">{ticket.title}</p>
                            <p className="flex-none text-xs text-gray-600">
                              <ReactTimeAgo date={ticket.createdAt} locale="en-US" />
                            </p>
                          </div>
                        </div>
                        {(ticket.former_ticket_id && ticket.former_ticket_id.length > 0) &&
                          <div className="text-xs text-black p-4 bg-blue-50" onClick={gotoReferencedId}>
                            <i>References Ticket: {ticket.former_ticket_id}</i>
                          </div>
                        }
                        <p className="w-full  md:line-clamp-5 text-xs leading-5 text-gray-600 pt-2">{ticket.details}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </main>
        <AsideRight className="hidden md:block" />
      </div>
      <Footer />
      <GoUpDown />
    </div>
  );
};

export default Support;
