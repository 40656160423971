import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import states from "../utils/states.json";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { register, registerViaGoogle } from "../core/auth";
import { Utils } from "../utils";
import Eye from "../components/icons/Eye";
import EyeSlash from "../components/icons/EyeSlash";

export default function Register() {


  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const bgVideo = "videos/bgvideo.mp4";

  const [role, setRole] = useState("");

  const DEFAULT_AVATAR = process.env.REACT_APP_DEFAULT_AVATAR;
  const USE_DEFAULT_AVATAR = process.env.REACT_APP_USE_DEFAULT_AVATAR === "true";
  const DEFAULT_LOGO = "/defaultCompanyLogo.png";
  const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
  const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";

  const [isLoading, setIsLoading] = useState(false);
  const [acceptsTerms, setAcceptsTerms] = useState(false);

  const [avatar, setAvatar] = useState(DEFAULT_AVATAR);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [companyLogoUrl, setCompanyLogoUrl] = useState(DEFAULT_LOGO);
  const [companyName, setCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState();
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [whereFindUs, setWhereFindUs] = useState("");


  const [errors, setErrors] = useState([]);
  const [passwordStrengthErrors, setPsswordStrengthErrors] = useState([]);
  const navigate = useNavigate();

  const clearForm = () => {
    setAvatar(DEFAULT_AVATAR);
    setFirstName("");
    setLastName("");
    setCompanyLogoUrl(DEFAULT_LOGO);
    setCompanyName("");
    setContactPerson("");
    setCompanyContact("");
    setCompanyAddress("");
    setUsername("");
    setEmail("");
    setCity("");
    setState("");
    setZipcode("");
    setPassword("");
    setPassword2("");
    setPsswordStrengthErrors([]);
    setWhereFindUs("");
  };

  const [isFileUpLoading, setIsFileUpLoading] = useState(false);
  const handleOnFileChange = async (changeEvent) => {
    const reader = new FileReader();
    reader.onload = async function (onLoadEvent) {
      setIsFileUpLoading(true);
      setAvatar(onLoadEvent.target.result);
      //https://www.youtube.com/watch?v=7lhUsK-FxYI
      // const form = e.currentTarget
      // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
      const fileInput = changeEvent.target;
      const formData = new FormData();
      formData.append('file', fileInput.files[0]);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      const data = await fetch(CLOUDINARY_URL, {
        method: "POST",
        body: formData
      })
        .then(r => r.json())
        .then((data) => {
          setAvatar(data.secure_url);
          setIsFileUpLoading(false);
        });
    }
    if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
      reader.readAsDataURL(changeEvent.target.files[0]);
    }
  };

  const handleOnLogoFileChange = async (changeEvent) => {
    const reader = new FileReader();
    reader.onload = async function (onLoadEvent) {
      setIsFileUpLoading(true);
      setCompanyLogoUrl(onLoadEvent.target.result);
      //https://www.youtube.com/watch?v=7lhUsK-FxYI
      // const form = e.currentTarget
      // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
      const fileInput = changeEvent.target;
      const formData = new FormData();
      formData.append('file', fileInput.files[0]);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      const data = await fetch(CLOUDINARY_URL, {
        method: "POST",
        body: formData
      })
        .then(r => r.json())
        .then((data) => {
          setCompanyLogoUrl(data.secure_url);
          setIsFileUpLoading(false);
        });
    }
    if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
      reader.readAsDataURL(changeEvent.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);

    //check accepts terms
    if (!acceptsTerms) {
      setErrors(["To continue please accept our terms and conditions"]);
      return false;
    }

    setIsLoading(true);
    let inputs = {
      avatar,
      firstName,
      lastName,
      username,
      email,
      password,
      password2,
      state,
      city,
      zipcode,
      role,
      companyLogoUrl,
      companyName,
      companyContact,
      companyAddress,
      whereFindUs
    };
    if (USE_DEFAULT_AVATAR === false && avatar === DEFAULT_AVATAR) {
      inputs.avatar = "";
    }
    if (inputs.role === "organisation") {
      inputs.username = companyName;
      inputs['contact_person_name'] = contactPerson;
    }
    console.log("contact_person_name", inputs);

    if (!checkPassword()) {

      setIsLoading(false);
      return false;
    }

    let response = await register(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }
    clearForm();
    const encodedEmail = btoa(email);
    navigate(`/activate?mb=${encodedEmail}`);
  };

  const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
  const handleSignupWithGoogle = async (credentialResponse) => {
    if (isLoading) {
      return false;
    }
    setErrors([]);

    // console.log("credentialResponse.clientId", credentialResponse.clientId);
    // console.log("credentialResponse.credential", credentialResponse.credential);
    setIsLoading(true);
    let inputs = {
      clientId: credentialResponse.clientId,
      jwtToken: credentialResponse.credential,
    }
    let response = await registerViaGoogle(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }
    navigate(`/`);
  }

  const handleOnJoinIndividual = () => { setRole("member") }
  const handleOnJoinOrganisation = () => { setRole("organisation") }
  const handleCloseForm = () => { setRole("") }

  const checkPassword = () => {
    // check for password strength
    setPsswordStrengthErrors([]);
    const res = Utils.chaeckPasswordStrength(password);
    if (res !== true) {
      setIsLoading(false);
      setPsswordStrengthErrors([res]);
      return false;
    }
    return true;
  }

  const whereFoundUs = [
    "Article or blog",
    "Bing Search",
    "Employer",
    "Events (dinners, activities, webinars, conferences)",
    "Facebook",
    "Family",
    "Friend",
    "Google Search",
    "Healthcare professional",
    "Instagram",
    "LinkedIn",
    "Online Forums",
    "Other",
    "Reddit",
    "School",
    "Social Media Influencer/blogger",
    "Websites for medical information",
    "X / Twitter"
  ];

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html className="h-full bg-white">
          <body className="h-full">
          ```
        */}

      <video
        className="hidden md:block absolute  w-auto min-w-full min-h-full max-w-screen "
        autoPlay
        muted
        loop
        src={bgVideo}></video>


      <div className="flex min-h-full flex-1 md:absolute">
        <div className="flex flex-1 flex-col min-h-full justify-center px-0 md:px-4 md:pb-12 lg:flex-none lg:px-20 xl:px-24 ">

          {role === "" &&
            <div className="flex flex-col md:flex-row items-center rounded-lg h-full md:h-auto w-full overflow-y-hidden" >

              <div className="relative md:mx-auto w-full md:w-[400px] bg-primary-white px-[15px] md:p-5 pt-24 md:pt-5 min-h-[120%] md:min-h-[50px] rounded-0 md:rounded-lg mt-0">
                <div className="flex flex-row justify-center items-center gap-y-2 pt-5x">
                  {/* <img
                    className="h-12 w-auto"
                    src="/logo.png"
                    alt="wefayo organization"
                  />
                  <a href={isLoading ? '#' : '/'} className={"" + (isLoading ? "cursor-not-allowed" : "")}>
                    <span className="text-3xl font-sans">
                      <b className="font-sans">wefayo </b>
                    </span>
                  </a> */}

                  <img class="h-12 w-auto" src="/new_wefayo_logo_and_word.png" alt="wefayo logo" />
                </div>



                <div className="bg-whitex p-4 rounded-lg xshadow-sm max-w-md mx-auto mt-10 md:mt-3">
                  <h1 className="text-xl font-semibold text-center my-2">Join Our Vibrant Community</h1>
                  <ul className="list-disc list-inside text-lg mb-1 pl-14 md:pl-10 ">
                    <li>🤝 Supportive Network</li>
                    <li>👨‍🎓 Knowledge Sharing</li>
                    <li>💡 Inspirational Stories</li>
                    <li>🤝 Collaboration Opportunities</li>
                    <li>🎉 Fun and Friendship</li>
                  </ul>
                </div>

                <button role="button" 
                  type="button"
                  disabled={isLoading}
                  className="app-sm-h app-sm-fs mb-2 mt-10 md:mt-2 flex justify-center items-center flex-row w-full rounded-sm bg-purple-900 px-3 py-2 text-md font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                  onClick={handleOnJoinIndividual} 
                >
                  {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                  😃 Join as an Individual
                </button>

                <div className="flex items-center justify-center text-center text-sm text-gray-300 py-2">
                  ---- OR ----
                </div>

                <button role="button" 
                  type="button"
                  disabled={isLoading}
                  className="app-sm-h app-sm-fs mt-2 mb-2 flex justify-center items-center flex-row w-full rounded-sm bg-purple-900 px-3 py-2 text-md font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                  onClick={handleOnJoinOrganisation}
                >
                  {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                  🏡 Join as an Organization
                </button>

              </div>

              <div className="hidden md:block w-[400px] md:w-[850px] pl-10 h-full ">
                {/* width="640" height="360"  */}
                <div className="aspect-video bg-white rounded-lg px-2 md:pt-5 h-full">
                  <iframe title="vimeo-player" className="w-full h-full " src="https://player.vimeo.com/video/925529909?h=48e17cddef"
                    frameborder="0" allowfullscreen>
                  </iframe>
                </div>
              </div>
            </div>
          }

          {(role === "member" || role === "organisation") &&
            <div className="md:mx-auto  h-full md:h-auto md:w-[550px] bg-primary-white px-[5px]  md:p-5 pt-5  relative rounded-lg md:shadow-md mt-0 md:mt-3" >

              <button role="button" 
                onClick={handleCloseForm}
                className="text-xl absolute right-2 top-2 p-2 w-[40px] h-[40px] hover:bg-purple-200 hover:text-purple-800">
                X
              </button>

              <div className="flex flex-col ">



                <div className="flex flex-row justify-center items-center gap-x-3 pt-5x">


                  {/* <img
                    className="h-10 w-auto"
                    src="/logo.png"
                    alt="wefayo organization"
                  />
                  <a href={isLoading ? '#' : '/'} className={"" + (isLoading ? "cursor-not-allowed" : "")}>
                    <span className="text-4xl font-sans">
                      <b className="font-sans">wefayo</b>
                    </span>
                  </a> */}

                  <img class="h-12 w-auto" src="/new_wefayo_logo_and_word.png" alt="wefayo logo" />


                </div>


                <h2 className="mt-1 text-xl text-center  font-bold leading-9 tracking-tight text-gray-900">
                  {role === "member" ? "Register to become a member" : "Register Your Organization"}
                </h2>

                <p className="text-center text-sm leading-6 text-gray-500">
                  Already a member?{' '}
                  <a href={isLoading ? '#' : '/login'} className={"font-semibold text-purple-900 hover:text-purple-500 " + (isLoading ? "cursor-not-allowed" : "")}>
                    Click here to login.
                  </a>
                </p>
              </div>

              <div className="mt-5">
                <div>
                  <form method="POST" className="space-y-2 px-4 md:px-0" onSubmit={handleSubmit}>



                    {role === "member" &&
                      <>


                        <div className="">
                          <div className="relative flex justify-center">
                            <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                              <GoogleLogin
                                size="medium"
                                text="signup_with"
                                onSuccess={handleSignupWithGoogle}

                                onError={(e) => {
                                  console.log('Signup with google failed:', e);
                                }}
                              />
                            </GoogleOAuthProvider>
                            {
                              isLoading && <div className="absolute bg-[#ffffff94] top-0 w-full h-[50px] cursor-not-allowed">&nbsp;</div>
                            }
                          </div>



                          <div className="pt-5 flex items-center w-full gap-x-3 ">

                            <div className="pt-x flex flex-col ">
                              <img
                                className="inline-block h-16 w-16 rounded-sm"
                                src={avatar}
                                alt=""
                              />
                            </div>

                            <div className="pt-2 flex flex-col flex-grow self-stretch justify-end items-start">
                              <label htmlFor="photo" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                Avatar
                              </label>

                              <div className="flex items-center justify-center bg-grey-lighter mt-2">
                                <label disabled={isLoading} htmlFor="select-avatar" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                  {!isFileUpLoading &&
                                    <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>
                                  }
                                  <span className={`text-xs font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                    {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                    {!isFileUpLoading &&
                                      <span>
                                        {(!avatar || avatar == DEFAULT_AVATAR) ? 'Select' : 'Change'} profile photo
                                      </span>
                                    }
                                    {isFileUpLoading && "uploading"}
                                  </span>
                                  <input id="select-avatar" disabled={isLoading} name="select-avatar" type='file' className="hidden" onChange={handleOnFileChange} />
                                </label>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="flex flex-col md:flex-row w-full gap-x-2 pt-2">
                          <div className="flex flex-col flex-grow">
                            <label htmlFor="firstname" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              First Name
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                id="firstname"
                                name="firstname"
                                type="text"
                                autoComplete=""
                                required
                                maxLength={100}
                                value={firstName}
                                disabled={isLoading}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>

                          <div className="flex flex-col  flex-grow">
                            <label htmlFor="firstname" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Last Name
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                id="lastname"
                                name="lastname"
                                type="text"
                                autoComplete=""
                                required
                                maxLength={100}
                                value={lastName}
                                disabled={isLoading}
                                onChange={(e) => setLastName(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    {role === "organisation" &&
                      <>

                        {/* company logo url */}
                        <div className="flex flex-col md:flex-row justify-between  gap-x-2 ">
                          <div className="flex-grow flex items-start gap-x-3 app-mb-15">

                            <div className="flex flex-col pt-2">
                              <img
                                className="inline-block h-16 w-16 rounded-sm"
                                src={companyLogoUrl}
                                alt=""
                              />
                            </div>

                            <div className="flex flex-col flex-grow self-stretch justify-end items-start ">

                              <div className="flex flex-col justify-start  pt-1">
                                <div className="block flex flex-row justify-start items-start   text-sm font-medium leading-6 text-gray-900 mb-2">
                                  &nbsp;
                                </div>
                                <label disabled={isLoading} htmlFor="select-logo" className={"block flex flex-row items-center px-3 py-1 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                  {!isFileUpLoading &&
                                    <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>
                                  }
                                  <span className={`text-sm font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                    {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                    {!isFileUpLoading &&
                                      <span>
                                        {(!companyLogoUrl || companyLogoUrl == DEFAULT_LOGO) ? 'Select' : 'Change'} Logo
                                      </span>
                                    }
                                    {isFileUpLoading && "uploading"}
                                  </span>
                                  <input id="select-logo" disabled={isLoading} name="select-logo" type='file' className="hidden" onChange={handleOnLogoFileChange} />
                                </label>
                              </div>
                            </div>

                          </div>

                          {/* company name */}
                          <div className="mt-3 md:mt-0  md:w-1/2 flex flex-col justify-end">
                            <label htmlFor="companyName" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Company Name
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                type="text"
                                name="companyName"
                                id="companyName"
                                className="app-sm-h font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                required
                                maxLength={100}
                                value={companyName}
                                disabled={isLoading}
                                onChange={(e) => setCompanyName(e.target.value)}
                              />
                            </div>
                          </div>

                        </div>

                        <div className="flex flex-col md:flex-row  justify-between gap-x-2 pt-5">

                          <div className="flex-grow">
                            <label htmlFor="contactPerson" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Contact Person
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                type="text"
                                name="contactPerson"
                                id="contactPerson"
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                required
                                maxLength={100}
                                value={contactPerson}
                                disabled={isLoading}
                                onChange={(e) => setContactPerson(e.target.value)}
                              />
                            </div>
                          </div>


                          <div className="flex-grow mt-2 md:mt-0">
                            <label htmlFor="email" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Company Email
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                maxLength={100}
                                value={email}
                                disabled={isLoading}
                                onChange={(e) => setEmail(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-x-2">
                          <div className="flex-grow">
                            <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Password
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                              <input
                                id="password"
                                name="password"
                                type={`${showPassword ? "text" : 'password'}`}
                                autoComplete="current-password"
                                required
                                maxLength={100}
                                value={password}
                                disabled={isLoading}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={(e) => checkPassword()}
                                className="app-sm-h app-mb-15 block w-full rounded-sm border-0 py-1 mb-1 md:mb-5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />

                              <button role="button" 
                                onClick={(e) => setShowPassword(!showPassword)}
                                type="button"
                                className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                              >
                                {showPassword ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                              </button>
                            </div>
                          </div>

                          <div className="flex-grow  md:mt-0">
                            <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Confirm Password
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                              <input
                                id="comfirm_password"
                                name="password"
                                type={`${showPassword2 ? "text" : 'password'}`}
                                autoComplete="current-password"
                                required
                                maxLength={100}
                                value={password2}
                                disabled={isLoading}
                                onChange={(e) => setPassword2(e.target.value)}
                                className="app-sm-h app-mb-15 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />

                              <button role="button" 
                                onClick={(e) => setShowPassword2(!showPassword2)}
                                type="button"
                                className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                              >
                                {showPassword2 ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                              </button>
                            </div>
                          </div>
                        </div>

                        {passwordStrengthErrors.length > 0 &&
                          <div className="relative pt-4">
                            <div className="app-error text-red-400 text-xs absolute -top-[20px]">
                              {passwordStrengthErrors[0]}
                            </div>
                          </div>
                        }

                        <div className="flex flex-col md:flex-row justify-between gap-x-2">
                          <div className="flex-grow">
                            <label htmlFor="company-contact" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Company Phone Number
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                type="text"
                                name="company-contact"
                                id="company-contact"
                                required
                                maxLength={100}
                                value={companyContact}
                                disabled={isLoading}
                                placeholder="000-000-0000"
                                onChange={(e) => setCompanyContact(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>

                          {/* physical address */}
                          <div className="flex-grow">
                            <label htmlFor="job-company-address" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Company Address
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                type="text"
                                name="job-company-address"
                                id="job-company-address"
                                required
                                maxLength={100}
                                value={companyAddress}
                                disabled={isLoading}
                                onChange={(e) => setCompanyAddress(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {role === "member" &&
                      <>
                        <div className="flex flex-col md:flex-row gap-x-2">
                          <div className="flex-grow">
                            <label htmlFor="email" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Email Address
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                maxLength={100}
                                value={email}
                                disabled={isLoading}
                                onChange={(e) => setEmail(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>

                          <div className="flex-grow">
                            <label htmlFor="email" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Screen Name
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0">
                              <input
                                id="username"
                                name="username"
                                type="text"
                                autoComplete=""
                                required
                                maxLength={100}
                                value={username}
                                disabled={isLoading}
                                onChange={(e) => setUsername(e.target.value)}
                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-x-2">
                          <div className="flex-grow">
                            <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Password
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                              <input
                                id="password"
                                name="password"
                                type={`${showPassword ? "text" : 'password'}`}
                                autoComplete="current-password"
                                required
                                maxLength={100}
                                value={password}
                                disabled={isLoading}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={(e) => checkPassword()}
                                className="app-sm-h block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />

                              <button role="button" 
                                onClick={(e) => setShowPassword(!showPassword)}
                                type="button"
                                className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                              >
                                {showPassword ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                              </button>
                            </div>
                          </div>

                          <div className="flex-grow">
                            <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                              Confirm Password
                              <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                              <input
                                id="comfirm_password"
                                name="password"
                                type={`${showPassword2 ? "text" : 'password'}`}
                                autoComplete="current-password"
                                required
                                maxLength={100}
                                value={password2}
                                disabled={isLoading}
                                onChange={(e) => setPassword2(e.target.value)}
                                className="app-sm-h block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                              />

                              <button role="button" 
                                onClick={(e) => setShowPassword2(!showPassword2)}
                                type="button"
                                className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                              >
                                {showPassword2 ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                              </button>
                            </div>
                          </div>
                        </div>

                        {passwordStrengthErrors.length > 0 &&
                          <div className="relative pt-4">
                            <div className="app-error text-red-400 text-xs absolute -top-[20px]">
                              {passwordStrengthErrors[0]}
                            </div>
                          </div>
                        }
                      </>
                    }


                    {/* <div className="flex flex-col flex-grow">
                      <label htmlFor="city" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                        City
                        <span className="text-red-900 mx-1">*</span>
                      </label>
                      <div className="mt-0">
                        <input
                          id="city"
                          name="city"
                          type="text"
                          autoComplete=""
                          required
                          maxLength={100}
                          value={city}
                          disabled={isLoading}
                          onChange={(e) => setCity(e.target.value)}
                          className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                        />
                      </div>
                    </div> */}

                    <div className="flex flex-col md:flex-row w-full gap-x-2 pb-2">

                      <div className="flex flex-col flex-grow">
                        <label htmlFor="city" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                          City
                          <span className="text-red-900 mx-1">*</span>
                        </label>
                        <div className="mt-0">
                          <input
                            id="city"
                            name="city"
                            type="text"
                            autoComplete=""
                            required
                            maxLength={100}
                            value={city}
                            disabled={isLoading}
                            onChange={(e) => setCity(e.target.value)}
                            className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col flex-grow">
                        <label htmlFor="state" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                          State
                          <span className="text-red-900 mx-1">*</span>
                        </label>
                        <div className="mt-0">
                          <select
                            id="state"
                            name="state"
                            type="text"
                            autoComplete=""
                            required
                            value={state}
                            disabled={isLoading}
                            onChange={(e) => setState(e.target.value)}
                            className="app-sm-h app-mb-15 font-bold first-option mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                          >
                            <option value="" key="first-state" selected disabled >Select State</option>
                            {states.map((state) => {
                              return (
                                <option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col md:w-[100px]">
                        <label htmlFor="zipcode" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                          Zip Code
                          <span className="text-red-900 mx-1">*</span>
                        </label>
                        <div className="mt-0">
                          <input
                            id="zipcode"
                            name="zipcode"
                            type="text"
                            autoComplete=""
                            required
                            value={zipcode}
                            disabled={isLoading}
                            maxLength={5}
                            onChange={(e) => setZipcode(e.target.value)}
                            className="app-sm-h font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                          />
                        </div>
                      </div>
                    </div>

                    {role === "member" &&
                      <div className="flex flex-col md:flex-row w-full gap-x-2 pb-2">
                        <div className="flex flex-col flex-grow">
                          <label htmlFor="state" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                            Where did you find us ?
                            <span className="text-red-900 mx-1"></span>
                          </label>
                          <div className="mt-0">
                            <select
                              id="whereFindUs"
                              name="whereFindUs"
                              type="text"
                              autoComplete=""
                              value={whereFindUs}
                              disabled={isLoading}
                              onChange={(e) => setWhereFindUs(e.target.value)}
                              className="app-sm-h app-mb-15 font-bold first-option mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                            >
                              <option value="" key="first-know" selected disabled ></option>
                              {whereFoundUs.map((where) => {
                                return (
                                  <option value={where} key={where}>{where}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    }

                    <div className="flex flex-row items-center app-mb-15">
                      <div className="flex h-6 items-center">
                        <input
                          id="accept-terms-checkbox"
                          name="accept-terms"
                          type="checkbox"
                          className="h-4 w-4 mb-0 rounded border-purple-900 text-purple-900 focus:ring-0 "
                          disabled={isLoading}
                          required
                          checked={acceptsTerms}
                          onChange={(e) => setAcceptsTerms(!acceptsTerms)}
                        />
                      </div>
                      <div className="app-sm-fs ml-3 text-sm leading-6 nowrap cursor-pointer">
                        <label htmlFor="accept-terms-checkbox" className="font-medium cursor-pointer text-gray-900">
                          Accept the
                        </label>
                        <a href="/terms" target="_blank" id="checkbox-terms-link" className="text-purple-900 px-1">
                          Terms
                        </a>
                        <label htmlFor="accept-terms-checkbox" className="font-medium text-gray-900 cursor-pointer ">
                          &
                        </label>
                        <a href="/privacy-policy" target="_blank" id="checkbox-policy-link" className="text-purple-900 px-1">
                          Privacy Policy
                        </a>
                        <span className="text-red-900 mx-1">*</span>
                      </div>
                    </div>

                    <div className="flex justify-between items-center py-4 md:py-0">


                      <button role="button" 
                        type="submit"
                        disabled={isLoading || passwordStrengthErrors.length > 0 || isFileUpLoading}
                        className="app-sm-h app-sm-fs  flex justify-center items-center flex-row flex-1 rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                      >
                        {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                        Sign Up
                      </button>
                    </div>
                  </form>
                  {errors.length > 0 &&
                    <div className="app-error text-red-400 font-bold text-xs py-1">
                      {errors[0]}
                    </div>
                  }
                </div>
              </div>
            </div>
          }

        </div>
        <div className="relative  flex-1 hidden md:block ">
        </div>
      </div>
    </>
  )
}
