import React, { Fragment, useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import PostHeaderSection from "../components/post/PostHeaderSection";
import PostHeroCard from "../components/post/PostHeroCard";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { useParams } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import OrderByDescending from "../components/icons/OrderByDescending";
import OrderByAscending from "../components/icons/OrderByAscending";
import XMarkIcon from "../components/icons/XMarkIcon";
import { deleteComment, deletePost, getPost, postComment, updateComment } from "../core/communities";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import DOMPurify from "dompurify";
import { Dialog, Transition } from '@headlessui/react';
import PostSkeleton from "../components/skeletons/PostSkeleton";
import ReplySkeleton from "../components/skeletons/ReplySkeleton";
import DeleteIcon from "../components/icons/Delete";
import LoginAlertModal  from "../components/auth/LoginAlertModal";




const Post = () => {
    const { comid, id, pid } = useParams();
    const navigate = useNavigate();

    const [breadcrumbPages, setBreadcrumbPages] = useState([]);

    const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE;
    const editorRef = useRef(null);
    const commentReplyRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false);

    const [errors, setErrors] = useState([]);
    const [replyErrors, setReplyErrors] = useState([]);

    const [post, setPost] = useState(null);
    const [cleanHtml, setCleanHtml] = useState("");
    const [editorKey, setEditorKey] = useState(4);
    const [commentDetails, setCommentDetails] = useState("<p></p>");
    const [referencedReplyId, setReferencedReplyId] = useState("");
    const [isDescOrder, setIsDescOrder] = useState(true);
    const [replyToCommentIndex, setReplyToCommentIndex] = useState(-1);
    const [commentToReplyDetails, setCommentToReplyDetails] = useState("<p></p>");
    const [commentMode, setCommentMode] = useState("post-comment");
    const [idOfReplyToEdit, setIdOfReplyToEdit] = useState(null);
    const [userRefs, setUserRefs] = useState({});

    const [isOwner, setIsOwner] = useState(false);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const fetchPost = async (useThisOrder) => {
        setIsLoading(true);
        let order = isDescOrder ? "desc" : "asc";
        if(typeof useThisOrder ==="string"){
            order = useThisOrder;
        }
        const postRes = await getPost(pid, order);
        //user refs from comments
        let refDic = {};
        for (let index = 0; index < postRes.replies.length; index++) {
            const reply = postRes.replies[index];
            if (!Object.hasOwnProperty.call(refDic, reply.user._id)) {
                refDic[reply.user._id] = { ...reply.user };
            }
        }
        setUserRefs(refDic);
        setPost(postRes);

        if (loggedInUserStr) {
            setIsOwner(postRes.user._id == loggedInUser._id);
        }

        setBreadcrumbPages([
            { name: postRes?.forum.community.title, href: `/`, current: false },
            { name: postRes?.forum.title, href: `/community/${comid}/forum/${id}`, current: false },
            { name: postRes?.title, href: '#', current: true }
        ]);

        clearForm();
        setIsLoading(false);
    }

    const updateLikesOfPost = (post, user, userAlreadyLikedPost = false) => {
        let postCopy = {
            ...post
        };
        if (userAlreadyLikedPost) {
            //remove user from likes
            let likers = [];
            postCopy.likes.forEach((liker) => {
                if (liker._id !== user._id) {
                    likers.push(liker);
                }
            });
            postCopy.likes = likers;
        } else {
            postCopy.likes.push(user);
        }
        setPost(postCopy);
    }


    const clearForm = (resetForReply = true) => {
        setCommentDetails("<p></p>");

        setEditorKey(editorKey * 2);
        setCommentToReplyDetails("<p></p>");
        setCommentMode("post-comment");
        if (resetForReply === true) {
            setReferencedReplyId("");
            setReplyToCommentIndex(-1);
        }
        setIdOfReplyToEdit(null);
    }

    const handleSubmitHelper = async (isComment=true) => {
        setShowSubmitLoading(false);
        setErrors([]);
        setReplyErrors([]);
        const wordCount = (isComment?editorRef:commentReplyRef).current.plugins.wordcount.getCount();
        if(wordCount === 0){
            if(isComment){
                setErrors(["Please add comment information"]);
            }else{
                setReplyErrors(["Please add reply information"]);
            }
            
            return false;
        }
        

        const inputs = {
            id: post._id,
            commentDetails: referencedReplyId.trim().length > 0 ? commentToReplyDetails : commentDetails,
            referencedReplyId
        };

        setShowSubmitLoading(true);
        let response = null;
        response = await postComment(inputs);
        setShowSubmitLoading(false);
        if (typeof response === 'object' && Array.isArray(response)) {
            if(isComment){
                setErrors(response);
            }else{
                setReplyErrors(response);
            }
            return false;
        }
        clearForm();
        toast.success('Comment was posted successfully', {
            icon: '✅'
        });
        let postCopy = {
            ...post
        };

        //todo
        //if we are in ascending add to the end of list i.e push
        //if we are in descending mode add to start of list 
        if(isDescOrder === true){
            postCopy.replies = [
                response,
                ... postCopy.replies
            ];
        }else{
            postCopy.replies.push(response);
        }
        
        //counts of replies for the user
        for (let index = 0; index < postCopy.replies.length; index++) {
            let rep = postCopy.replies[index];
            if (rep.user._id === loggedInUser._id) {
                rep.user.count_of_replies += 1;
            }
            postCopy.replies[index] = rep;
        }
        postCopy.count_of_replies = parseInt(postCopy.count_of_replies) + 1;
        if (postCopy.user._id === loggedInUser._id) {
            postCopy.user.count_of_replies += 1;
        }
        setPost(postCopy);
        //scroll to bottom
        // handleClickScroll("bottom-comments");
    }

    const handleEditComment = async (isComment=true) => {
        setShowSubmitLoading(false);
        setErrors([]);
        setReplyErrors([]);
        const wordCount = (isComment?editorRef:commentReplyRef).current.plugins.wordcount.getCount();
        if(wordCount === 0){
            if(isComment){
                setErrors(["Please add comment information"]);
            }else{
                setReplyErrors(["Please add reply information"]);
            }
            
            return false;
        }

        const inputs = {
            id: idOfReplyToEdit,
            commentDetails: commentToReplyDetails,
        };
        let response = null;
        response = await updateComment(inputs);
        setShowSubmitLoading(false);
        if (typeof response === 'object' && Array.isArray(response)) {
            if(isComment){
                setErrors(response);
            }else{
                setReplyErrors(response);
            }
            return false;
        }
        toast.success('Comment was updated successfully', {
            icon: '✅'
        });
        let postCopy = {
            ...post
        };
        //look for the reply and update it
        for (let index = 0; index < postCopy.replies.length; index++) {
            const reply = postCopy.replies[index];
            if (reply._id === response._id) {
                postCopy.replies[index] = response;
                break;
            }
        }
        // setPost(postCopy);
        clearForm();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);

        if (commentMode === "edit-comment") {
            handleEditComment(true);
        } else {
            handleSubmitHelper(true);
        }
    }

    const handleSubmitReply  = async (e) => {
        e.preventDefault();
        setReplyErrors([]);

        if (commentMode === "edit-comment") {
            handleEditComment(false);
        } else {
            handleSubmitHelper(false);
        }
    }

    const handleClear = (e) => {
        e.preventDefault();
        clearForm();
    }

    useEffect(() => {
        fetchPost();
        window.scrollTo(0, 0);
    }, []);

    const handleChangeOrder = (e) => {
        e.preventDefault();
        setIsDescOrder(!isDescOrder);
        setTimeout(() => {
            fetchPost(isDescOrder? "asc" : "desc");
        }, 100);
    }

    const responseToReplyHeader = "<b id='res-to-reply-header' style='margin-bottom:20px;'>You are responding to the comment above</b><br/><br/>";
    const openCommentFormAfter = (e, comment) => {
        e.preventDefault();

        if (loggedInUserStr) {
            //look for the comment index
            const filtered = post.replies.filter((rep) => !rep.is_marked_for_deleting);
            for (let index = 0; index < filtered.length; index++) {
                const reply = filtered[index];
                
                if (reply._id === comment._id) {
                    setReferencedReplyId(reply._id);
                    setCommentToReplyDetails(`<p></p>`);
                    setEditorKey(editorKey * 2);
                    setReplyToCommentIndex(index);
                    setCommentMode("post-comment");
                    break;
                }
            }
        }else{
            navigate(`/login`);
        }  
    }

    const openEditCommentFormAfter = (e, commentObj) => {
        openCommentFormAfter(e, commentObj);
        if (commentObj.referenced_reply) {
            setReferencedReplyId(commentObj.referenced_reply._id);
        } else {
            setReferencedReplyId("");
        }
        const detailsToEdit = commentObj.details;
        const decodedDetailsToEdit = atob(detailsToEdit);
        let htmlToEdit = DOMPurify.sanitize(decodedDetailsToEdit, {
            USE_PROFILES: { html: true },
        });
        const cleanHtmlToEdit = decodeURIComponent(htmlToEdit);
        setCommentToReplyDetails(cleanHtmlToEdit);
        setEditorKey(editorKey * 2);
        setCommentMode("edit-comment");
        setIdOfReplyToEdit(commentObj._id);
    }

    const openDeleteComment = (e, commentObj) => {
        e.preventDefault();
        setIdOfReplyToEdit(commentObj._id);
        //open a dialogue box 
        setIsModalOpen(true);
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: idOfReplyToEdit
        };
        let response = null;
        //normal post
        response = await deleteComment(inputs);
        setIsLoading(false);
        if (typeof response === 'object' && Array.isArray(response)) {
            return setErrors(response);
        }
        setIsModalOpen(false);
        toast.success('The Comment was deleted successfully', {
            icon: '✅'
        });
        let postCopy = {
            ...post
        };
        //look for the reply and update it
        for (let index = 0; index < postCopy.replies.length; index++) {
            const reply = postCopy.replies[index];
            if (reply._id === response._id) {
                postCopy.replies[index] = response;
                break;
            }
        }
        //counts of replies for the user
        for (let index = 0; index < postCopy.replies.length; index++) {
            let rep = postCopy.replies[index];
            if (rep.user._id === loggedInUser._id) {
                rep.user.count_of_replies -= 1;
            }
            postCopy.replies[index] = rep;
        }
        postCopy.count_of_replies = parseInt(postCopy.count_of_replies) - 1;
        if (postCopy.user._id === loggedInUser._id) {
            postCopy.user.count_of_replies -= 1;
        }
        setPost(postCopy);
        clearForm();

    }

    const updateLikesOfComment = (theReply, theUser, userAlreadyLikedPost = false) => {
        let postCopy = {
            ...post
        }
        let upReplies = [];
        if (post.replies) {
            for (let index = 0; index < post.replies.length; index++) {
                let reply = post.replies[index];
                if (reply._id === theReply._id) {
                    if (userAlreadyLikedPost) {
                        //remove user from likes
                        let likers = [];
                        reply.likes.forEach((liker) => {
                            if (liker._id !== theUser._id) {
                                likers.push(liker);
                            }
                        });
                        reply.likes = likers;
                    } else {
                        reply.likes.push(theUser);
                    }
                }
                upReplies.push(reply);
            }
        }
        postCopy.replies = upReplies;
        setPost(postCopy);
    }

    const handleCancelCommentToReply = (e) => {
        e.preventDefault();
        clearForm();
    }

    const handleClearCommentToReply = (e) => {
        e.preventDefault();
        clearForm(false);
    }

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const cancelButtonRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleDeleteModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const closeFun = () => {
        setIsModalOpen(false);
    }
    const goToLogin = () => {
        navigate(`/login`);
    }


    const cancelButtonRefDeletePost = useRef(null);
    const [isModalDeletePostOpen, setIsModalDeletePostOpen] = useState(false);
    const toggleDeletePostModal = () => {
        setIsModalDeletePostOpen(!isModalDeletePostOpen);
    };
    const closeDeletePostFun = () => {
        setIsModalDeletePostOpen(false);
    }
    const onOpenDeletePost = (e, postObj) => {
        e.preventDefault();
        setIsModalDeletePostOpen(true);
    }
    const handleDeletePost = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: post._id
        };
        let response = null;
        response = await deletePost(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The Post was deleted successfully', {
            icon: '✅'
        });
        navigate(`/community/${comid}/forum/${id}`);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };


    return (
        <div className="flex min-h-full flex-col bg-[#dae0e6]">
            <NavHeader currentPage={"forums"} />
            <div className="mt-[50px]">
                <PostHeaderSection
                    post={post}
                    forum={post?.forum}
                    breadcrumbPages={breadcrumbPages}
                />
            </div>
            <div className=" mx-auto flex flex-col md:flex-row w-full items-start  max-w-6xl gap-x-5 px-[5px] md:px-4 py-[10px] md:py-4">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" ads={post?.forum?.ads ?? []} />
                <div className="flex-grow flex flex-col flex-grow w-full ">
                    {isLoading &&
                        <>
                            <PostSkeleton />
                            <ReplySkeleton />
                            <ReplySkeleton />
                        </>
                    }
                    {!isLoading &&
                        <>
                            <div className="flex flex-col ">
                                {post &&
                                    <PostHeroCard
                                        post={post}
                                        forum={post?.forum}
                                        isOwner={isOwner}
                                        onUserLikedPost={updateLikesOfPost}
                                        onOpenDeletePost={onOpenDeletePost}
                                    />
                                }
                            </div>

                            {loggedInUser &&
                                <div className="mb-5 ">
                                    <div className="shadow-md">
                                        <Editor
                                            key={'editorX-' + editorKey}
                                            apiKey={TINY_MCE_API_KEY}
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            onEditorChange={(text) => setCommentDetails(text)}
                                            value={commentDetails}
                                            init={{
                                                height: 250,
                                                menubar: false,
                                                plugins: 
                                                    'advlist autolink lists link image  anchor ' + 
                                                    'searchreplace visualblocks ' +
                                                    'media table past wordcount'
                                                ,
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: inherit !important; } input, textarea{font-size: inherit !important;}'
                                            }}
                                        />
                                    </div>
                                    <div className="flex justify-between p-2 bg-primary-white rounded-b-md ">
                                        <span className="isolate inline-flex rounded-sm ">
                                            {loggedInUser?.role === "admin" &&
                                                <button role="button" aria-label="clear comment"  type="button" onClick={handleClear} disabled={showSubmitLoading} className="relative inline-flex items-center rounded-sm bg-gray-300x px-2 py-0.5 text-sm font-semibold text-purple-900 hover:bg-purple-200 disabled:cursor-not-allowed">
                                                    <DeleteIcon />
                                                </button>
                                            }
                                        </span>

                                        <button role="button" 
                                            type="button"
                                            disabled={showSubmitLoading}
                                            onClick={handleSubmit}
                                            aria-label="Submit Comment"
                                            className="flex justify-center rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                        >
                                            {showSubmitLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit Comment
                                        </button>
                                    </div>
                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-3 px-3 ">
                                            {errors[0]}
                                        </div>
                                    }
                                </div>
                            }
                            <LoginAlertModal message="Login to comment" isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
                            {!loggedInUser &&
                                <div className="flex justify-between p-2 bg-primary-white mb-10 ">
                                    <div></div>
                                    <a
                                        onClick={toggleLoginAlertModalModal}
                                        className="cursor-pointer flex justify-center rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                    >
                                        Comment
                                    </a>
                                </div>
                            }

                            {post?.replies.length > 0 &&
                                <div className="flex justify-between items-center bg-purple-900 rounded-t-md ">
                                    <div
                                        className="rounded-sm  px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 "
                                    >
                                        <b className="text-purple-200">{post?.replies.length}</b> Comment{post?.replies.length>1?'s':''}
                                    </div>

                                    <button role="button" aria-label="toggle display order either ascending or descending"  type="button" onClick={handleChangeOrder} className="relative inline-flex items-center rounded-sm bg-gray-300 px-5 py-0.5 my-2 mr-2 font-semibold text-purple-900 hover:bg-gray-200 ">
                                        
                                        {isDescOrder &&
                                            <OrderByDescending />
                                        }
                                        {!isDescOrder &&
                                            <OrderByAscending />
                                        }
                                    </button>
                                </div>
                            }
                            {post?.replies.filter((rep) => !rep.is_marked_for_deleting).map((reply, index) => (
                                <div className="flex flex-col mb-0 md:mb-1 w-full rounded-md ">
                                    <PostHeroCard
                                        post={reply}
                                        isComment={true}
                                        isOwner={isOwner}
                                        onUserLikedComment={updateLikesOfComment}
                                        onOpenCommentFormAfter={openCommentFormAfter}
                                        index={index}
                                        userRefs={userRefs}
                                        onOpenEditCommentFormAfter={openEditCommentFormAfter}
                                        onOpenDeleteComment={openDeleteComment}
                                        hasTinyMCE={replyToCommentIndex === index}
                                    />
                                    
                                    {(replyToCommentIndex === index) &&
                                        <>
                                            
                                            <Editor
                                                key={'editorComment-' + editorKey + "-" + replyToCommentIndex}
                                                apiKey={TINY_MCE_API_KEY}
                                                onInit={(evt, editor2) => commentReplyRef.current = editor2}
                                                onEditorChange={(text) => setCommentToReplyDetails(text)}
                                                value={commentToReplyDetails}
                                                init={{
                                                    height: 250,
                                                    menubar: false,
                                                    plugins: 
                                                        'advlist autolink lists link image  anchor ' + 
                                                        'searchreplace visualblocks ' + 
                                                        'media table past wordcount'
                                                    ,
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: inherit !important; } input, textarea{font-size: inherit !important;}'
                                                }}
                                            />

                                            <div className="flex justify-between p-2 bg-primary-white rounded-b-md ">
                                                <span className="isolate inline-flex rounded-sm  gap-x-5 ">
                                                    <button role="button" aria-label="cancel commenting to reply"  type="button" onClick={handleCancelCommentToReply} className="rounded-sm text-gray-600 text-sm font-semibold hover:text-purple-600 ">
                                                        <XMarkIcon className="w-6" />
                                                    </button>

                                                    {commentMode === "post-comment" &&
                                                        <button role="button" 
                                                            aria-label="clear comment"
                                                            type="button"
                                                            onClick={handleClearCommentToReply}
                                                            className="rounded-sm text-gray-600 text-sm font-semibold hover:text-red-600 "
                                                        >
                                                            <DeleteIcon />
                                                        </button>
                                                    }
                                                </span>

                                                <button role="button" 
                                                    type="button"
                                                    aria-label={commentMode==="edit-comment" ? "Edit Reply" : "Submit Reply"}
                                                    disabled={showSubmitLoading}
                                                    onClick={handleSubmitReply}
                                                    className="flex justify-center rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                                >
                                                    {showSubmitLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                    {commentMode==="edit-comment" ? "Edit Reply" : "Submit Reply"}
                                                </button>

                                            </div>

                                            {replyErrors.length > 0 &&
                                                <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                                    {replyErrors[0]}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            ))}
                        </>}
                    <div id="bottom-comments"></div>
                </div>
                <AsideRight className="hidden md:block" ads={post?.forum?.ads ?? []} />
            </div>
            <Footer />
            <GoUpDown />

            {/* Delete Post */}
            <Transition.Root show={isModalDeletePostOpen} as={Fragment}>
                <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRefDeletePost} open={isModalDeletePostOpen} onClose={closeDeletePostFun}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div onClick={() => closeDeletePostFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56">

                                    <div className="bg-white mx-4 rounded-md">

                                        <div className="bg-primary-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-t-md items-center ">
                                            <div className="text-gray-900 text-md font-bold">
                                                Delete This Post
                                            </div>
                                        </div>

                                        <div className="overflow-x-auto rounded-b-sm p-5 text-lg">
                                            Deleting the post deletes all its comments and likes. Do you want to continue with this action ?
                                        </div>

                                        <div className="bg-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-b-md items-center ">
                                            <button role="button" 
                                                aria-label="cancel deleting"
                                                onClick={(e) => setIsModalDeletePostOpen(false)}
                                                className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                            >
                                                Cancel
                                            </button>

                                            <button role="button" 
                                                type="button"
                                                aria-label="confirm delete"
                                                disabled={isLoading}
                                                onClick={handleDeletePost}
                                                className="w-[200px] flex justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:cursor-not-allowed"
                                            >
                                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                Delete
                                            </button>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Delete comment */}
            <Transition.Root show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isModalOpen} onClose={closeFun}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div onClick={() => closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className=" relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56">

                                    <div className="bg-primary-white mx-0 md:mx-4 rounded-md">

                                        <div className="bg-primary-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-t-md items-center ">
                                            <div className="text-gray-900 text-md font-bold">
                                                Delete This Comment
                                            </div>
                                        </div>

                                        <div className="overflow-x-auto rounded-b-sm p-5 text-lg">
                                            This action will also delete it's likes. Do you want to continue with this action ?
                                        </div>

                                        <div className="bg-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-b-md items-center ">
                                            <button role="button" 
                                                aria-label="cancel deleting comment"
                                                onClick={(e) => setIsModalOpen(false)}
                                                className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                            >
                                                Cancel
                                            </button>

                                            <button role="button" 
                                                type="button"
                                                aria-label="delete comment"
                                                disabled={isLoading}
                                                onClick={handleDelete}
                                                className="w-[200px] flex justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:cursor-not-allowed"
                                            >
                                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                Delete
                                            </button>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};

export default Post;