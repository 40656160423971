import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";

const TermsOfUse = () => {

    return (
        <div className=" min-h-full flex flex-col bg-primary-gray">
            <NavHeader currentPage={"terms"} />
            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px]  gap-x-5 px-[5px] md:px-4 py-[30px] md:py-11 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 w-full">
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col bg-primary-white rounded-md ">
                            <div class="mx-auto max-w-2xl text-sm">
                                <article>
                                    <header class="flex flex-col ">
                                        <h1 class="text-lg px-8 pt-3 font-bold tracking-tight text-zinc-800 sm:text-lg">
                                            Terms and Conditions
                                        </h1>
                                    </header>

                                    <div class="mt-5 px-8 pt-1 pb-5 prose flex flex-col gap-y-5"  >
                                        <p>
                                            By using or accessing the website ("Service", "SERVICE"), you (“User”, “You”, “Author”, “Individual(s)”) accept and agree to be bound by the following terms and conditions. If you do not wish to be bound by them, then please do not accept these terms and conditions, or use the Service.
                                        </p>
                                        <p>
                                            You understand that all content, information, data, text, images, audio, links, or other materials ("Content") appearing in the Service is the sole responsibility of those persons posting it. This means that You, not Wefayo, are entirely responsible for all Content that You post, generate, upload, submit, publish, transmit, or message (“Post,” “Posted”).
                                        </p>
                                        <p>
                                            You agree not to use the Service to submit or link to any Content which is defamatory, abusive, hateful,
                                            threatening, spam or spam-like, likely to offend, contains pornographic or objectionable content,
                                            contains personal information of others, risks copyright infringement, encourages unlawful activity, or
                                            otherwise violates any United States laws.
                                        </p>
                                        <p>
                                            All Content you Post may be reviewed by Staff members. All Content you Post may be sent to third-party
                                            services (including, but not limited to, spam prevention services).
                                        </p>
                                        <p>
                                            We reserve the right to remove or modify any Content Posted for any reason without explanation.
                                            Requests for removing or modifying Content will be undertaken only at our discretion. We reserve the
                                            right to act (including, but not limited to suspend, deactivate) against any account with the Service at
                                            any time.
                                        </p>
                                        <p>
                                            <span>
                                                You agree not to do any of the following while using the Service:
                                            </span>
                                            <ul className="list-decimal pl-5">
                                                <li>post, generate, upload, submit, publish, transmit, message (“Post”), information, data, text,
                                                    images, audio, links, or other materials ("Content"). that is unlawful, harmful, threatening,
                                                    abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, that may be invasive of
                                                    another's privacy, hateful, racially, ethnically or otherwise objectionable;
                                                </li>
                                                <li>
                                                    impersonate any person or entity, including but not limited to Us, moderator(s), or falsely state
                                                    or otherwise misrepresent your affiliation with a person or entity;
                                                </li>
                                                <li>
                                                    Post any Content that you do not have a right to under any law or under contractual or fiduciary
                                                    relationships (such as nondisclosure agreements);
                                                </li>
                                                <li>
                                                    Post any unsolicited advertising, promotional materials, "junk mail", "spam", "chain letters",
                                                    "pyramid schemes", or any other form of solicitation;
                                                </li>
                                                <li>
                                                    intentionally or unintentionally violate any applicable local, state, national or international law,
                                                    and any regulations having the force of law while using or accessing the Service; and
                                                </li>
                                                <li>
                                                    Post any Content that infringes any patent, trademark, trade secret, copyright or other
                                                    proprietary rights ("Rights") of any party. By Posting Content, you automatically grant, (or
                                                    warrant that the owner of such Content has expressly granted) the royalty-free, perpetual,
                                                    assignable and sublicensable right and license to use, reproduce, modify, adapt, publish,
                                                    translate, create derivative works from, distribute, perform and display such Content (in whole
                                                    or part) worldwide and/or to incorporate it in other works in any form, media, or technology
                                                    now known or later developed.
                                                </li>
                                            </ul>
                                        </p>
                                        <p>
                                            Wefayo and its designees shall have the right in their sole discretion to edit, refuse to post, or remove
                                            any Content Posted, or move it to other areas of the Service. Without limiting the foregoing, Wefayo
                                            and its designees shall have the right to remove any Content that violates the provisions hereof or is
                                            otherwise objectionable. You agree to indemnify and hold Wefayo harmless from any claim or demand,
                                            including reasonable attorneys' fees, made by any third party due to or arising out of your violation of
                                            these terms and conditions or your violation of any rights of another.
                                        </p>
                                    </div>

                                </article>
                            </div>
                        </div>
                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default TermsOfUse;

