import React from "react";
import LeaderBoard from "../leaderboard/LeaderBoard";
import ActivitiesBoard from "../leaderboard/ActivitiesBoard";

const AsideLeft = ({shows="nothing"}) => {

    return (
        <aside className="block mb-[5px] rounded-md hidden md:sticky md:mb-5 md:mb-0  top-15 w-full md:w-56 shrink-0 lg:block bg-white p-0 md:p-1">
            {shows === "nothing" && 
                <x-placeholder message="Left column area">
                    <div className="relative h-fit md:h-[576px] overflow-hidden border border-dashed border-gray-400 opacity-75">
                        <svg className="absolute inset-0 h-full w-full stroke-gray-900/10" fill="none">
                            <defs>
                                <pattern id="pattern-e65c4c0f-2107-4ff8-8f1a-e4204a4fd15f" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                                    <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
                                </pattern>
                            </defs>
                            <rect stroke="none" fill="url(#pattern-e65c4c0f-2107-4ff8-8f1a-e4204a4fd15f)" width="100%" height="100%"></rect>
                        </svg>
                    </div>
                </x-placeholder>
            }
            {shows === "leaderboard" && 
                <LeaderBoard />
            }

            <ActivitiesBoard />
            
        </aside>
    );
};



export default AsideLeft;