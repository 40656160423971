import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/communities";
import { getJobListing } from "../core/job_listing";
import EditIcon from "../components/icons/Edit";
import PlusCircle from "../components/icons/PlusCircle";
import ReactTimeAgo from 'react-time-ago';
import { useNavigate } from "react-router-dom";


const ForumAds = () => {

    const navigate = useNavigate();

    const [communities, setCommunities] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [jobListing, setJobListing] = useState(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const fetchCommunities = async () => {
        setIsLoading(true);

        let communityBudges = {};
        if (loggedInUser !== null) {
            communityBudges = await getCommunityBudges();
            setCommunityBudges(communityBudges);
        } else {
            communityBudges = await getAnonymousCommunityBudges();
            setCommunityBudges(communityBudges);
        }

        const data = await getCommunities();
        setCommunities(data);
        setIsLoading(false);
    }

    const handleAddAdClick = (e) => {
        e.preventDefault();
        navigate(`/forumads/add`);
    }

    useEffect(() => {

        fetchCommunities();

    }, []);

    


    return (
        <div className=" min-h-full flex flex-col bg-primary-gray">
            <NavHeader currentPage={"forumads"} />
            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px]  gap-x-5 px-[5px] md:px-4 py-[30px] md:py-8 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 w-full">
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col ">

                            {isLoading &&
                                <>
                                    <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                                </>
                            }
                            {!isLoading &&
                                <div className="mx-auto w-full  mb-20 md:mb-0 relative rounded-md flex flex-col flex-1 " >

                                    <div className="flex justify-between items-center bg-primary-white font-bold  rounded-t-md border-b border-gray-200 p-2">
                                        <h5 className="text-lg">Forum Ads</h5>
                                        <div className="flex flex-row gap-x-2">
                                            <span>All</span>
                                            <span>Active ads</span>
                                            <span>Expired ads</span>
                                            <span>Closed</span>

                                            <a
                                                onClick={(e) => handleAddAdClick(e)}
                                                className="pointer flex flex-row gap-x-[2px] cursor-pointer rounded-sm text-purple-900 px-3 py-1.5 text-sm md:text-md font-bold hover:text-purple-800 "
                                            >
                                                <PlusCircle />
                                                Create Ad
                                            </a>
                                        </div>
                                    </div>

                                    <div className="text-sm w-full flex-1 flex flex-col justify-start items-start bg-white">
                                        <div className="flex flex-row self-stretch p-1">
                                            <div className="max-w-[200px] cursor-pointer">
                                                <img
                                                    className="inline-block rounded-sm "
                                                    src="/grout_aside_image.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="flex flex-col flex-grow p-2">
                                                <div className="h-[80px] md:h-auto flex items-center  flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Title
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center  flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Navigate To
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center  flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Date Posted
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center  flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Date Expired
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center  flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Status
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Added By
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center flex-col md:flex-row gap-y-2  border-b border-gray-200 ">
                                                    <div className="w-[100px]">
                                                        Forum
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                                <div className="h-[80px] md:h-auto flex items-center flex-col md:flex-row gap-y-2 border-b border-gray-200">
                                                    <div className="w-[100px]">
                                                        Description
                                                    </div>
                                                    <div className=" text-md font-bold capitalize ">
                                                        kampala
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default ForumAds;

