import React, { useRef } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import labData from "../utils/labData";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Avatar from "../components/icons/Avatar";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import LikeIcon from "../components/icons/Like";
import { Editor } from '@tinymce/tinymce-react';
import ForumHeroSection  from '../components/forum/ForumHeroSection';
import { Utils } from "../utils";
import { useParams } from "react-router-dom";
import { useState } from 'react';
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";

const JobForm = () => {

    
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
          console.log(editorRef.current.getContent());
        }
    };

    const [companyLogoUrl, setCompanyLogoUrl] = useState('');
    const handleChange = (event) => {
        setCompanyLogoUrl(event.target.value);
    };

    const community = labData.communities[0];


    return (
        <div className="flex min-h-full flex-col bg-[#dae0e6]">
            <NavHeader currentPage={"commuity-request"} />

            <div className="mx-auto flex w-full items-start mt-[24px] max-w-7xl gap-x-4 px-4 py-10 sm:px-6 lg:px-8">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1">

                    <JobListingCommunityAccordion
                        community={community}
                        isOpenInitially={true}
                        isAdmin={true}
                    />
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default JobForm;
