import { validateAndSend } from "../index";
import {
    registerEndPoint,
    accountActivateEndPoint,
    resendAccountActivationCodeEndPoint,
    loginEndPoint,
    logoutEndPoint,
    registerViaGoogleEndPoint,
    loginViaGoogleEndPoint,
    forgotPasswordEndPoint,
    resetPasswordEndPoint,
    resendResetPasswordCodeEndPoint,
    changeEmailEndPoint,
    verifyEmailEndPoint,
    resendVerifyNewCodeEndPoint,
    updatePasswordEndPoint,
    updateProfileEndPoint,
    getProfileEndPoint,
    getPublicProfileEndPoint
} from "../endPoints";

import { registerRules, updateProfileRules } from "./registerRules";
import { accountActivationRules } from "./accountActivationRules";
import { resendAccountActivationCodeRules } from "./resendAccountActivationCodeRules";
import { loginRules } from "./loginRules";
import { forgotPassowrdRules } from "./forgotPassowrdRules";
import { resetPasswordRules } from "./resetPasswordRules";
import { resendResetPasswordCodeRules } from "./resendResetPasswordCodeRules";
import { changeEmailRules } from "./changeEmailRules";
import { verifyNewEmailRules } from "./verifyNewEmailRules";
import { resendVerifyNewCodeRules } from "./resendVerifyNewCodeRules"
import { updatePasswordRules } from "./updatePasswordRules";
import { destroyToken, setSession } from "../../utils/auth";

import Cookies from "js-cookie";

const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME || "";

export const set_session_helper = (response) => {
    setSession(response);
}

export const register = async (inputs) => {
    let name = `${inputs.firstName} ${inputs.lastName}`;
    let imageUrl = inputs.avatar;
    if(inputs.role == "organisation"){
        name = inputs.companyName;
        imageUrl = inputs.companyLogoUrl
    }

    let formData = {
        name: name,
        username: inputs.username,
        email: inputs.email,
        password: inputs.password,
        comfirm_password: inputs.password2,
        avatar: imageUrl,
        contact: inputs.companyContact,
        address: inputs.companyAddress,
        location: {
            city: inputs.city,
            state: inputs.state,
            zipcode: inputs.zipcode
        },
        role: inputs.role,
        where_did_you_find_us: inputs.whereFindUs
    }
    if(inputs.role == "organisation"){
        formData.contact_person_name = inputs.contact_person_name;
    }
    
    return validateAndSend({
        endPoint: registerEndPoint,
        inputs: inputs,
        rules: registerRules,
        formData: formData
    });
};

export const activateAccount = async (inputs) => {
    return validateAndSend({
        endPoint: accountActivateEndPoint,
        inputs: inputs,
        rules: accountActivationRules,
        formData: {
            email: inputs.email,
            code: inputs.code,
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
}

export const resendActivationCode = async (inputs) => {
    return validateAndSend({
        endPoint: resendAccountActivationCodeEndPoint,
        inputs: inputs,
        rules: resendAccountActivationCodeRules,
        formData: {
            email: inputs.email
        }
    });
}



export const login = async (inputs) => {
    return validateAndSend({
        endPoint: loginEndPoint,
        inputs: inputs,
        rules: loginRules,
        formData: {
            email: inputs.email,
            password: inputs.password
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
};

export const logout_helper = () => {
    return logout();
}

export const logout = async () => {
    return destroyToken();
};


export const registerViaGoogle = async (inputs) => {
    return validateAndSend({
        endPoint: registerViaGoogleEndPoint,
        formData: {
            client_id: inputs.clientId,
            jwtToken: inputs.jwtToken,
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
};


export const loginViaGoogle = async (inputs) => {
    return validateAndSend({
        endPoint: loginViaGoogleEndPoint,
        formData: {
            client_id: inputs.clientId,
            jwtToken: inputs.jwtToken,
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
};

export const forgotPassword = async (inputs) => {
    return validateAndSend({
        endPoint: forgotPasswordEndPoint,
        inputs: inputs,
        rules: forgotPassowrdRules,
        formData: {
            email: inputs.email
        }
    });
};

export const resetPassword = async (inputs) => {
    return validateAndSend({
        endPoint: resetPasswordEndPoint,
        inputs: inputs,
        rules: resetPasswordRules,
        formData: {
            email: inputs.email,
            code: inputs.code,
            password: inputs.password,
            comfirm_password: inputs.password2,
        }
    });
}

export const resendResetPasswordCode = async (inputs) => {
    return validateAndSend({
        endPoint: resendResetPasswordCodeEndPoint,
        inputs: inputs,
        rules: resendResetPasswordCodeRules,
        formData: {
            email: inputs.email
        }
    });
}

export const changeEmail = async (inputs) => {
    return validateAndSend({
        endPoint: changeEmailEndPoint,
        inputs: inputs,
        rules: changeEmailRules,
        formData: {
            old_email: inputs.currentEmail,
            new_email: inputs.newEmail,
            password: inputs.password,
        },
        onSuccess: (response) => {
            logout_helper();
        }
    });
}

export const verifyNewEmail = async (inputs) => {
    return validateAndSend({
        endPoint: verifyEmailEndPoint,
        inputs: inputs,
        rules: verifyNewEmailRules,
        formData: {
            old_email: inputs.currentEmail,
            new_email: inputs.newEmail,
            code: inputs.code,
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
}


export const resendVerifyNewEmailCode = async (inputs) => {
    return validateAndSend({
        endPoint: resendVerifyNewCodeEndPoint,
        inputs: inputs,
        rules: resendVerifyNewCodeRules,
        formData: {
            old_email: inputs.currentEmail,
            new_email: inputs.newEmail,
        }
    });
}

export const updatePassword = async (inputs) => {
    return validateAndSend({
        endPoint: updatePasswordEndPoint,
        inputs: inputs,
        rules: updatePasswordRules,
        formData: {
            old_password: inputs.currentPassword,
            new_password: inputs.newPassword,
            comfirm_password: inputs.comfirmPassword
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
}

export const updateProfile = async (inputs) => {
    let name = `${inputs.firstName} ${inputs.lastName}`;
    let imageUrl = inputs.avatar;
    if(inputs.role == "organisation"){
        name = inputs.companyName;
        imageUrl = inputs.companyLogoUrl
    }
    
    return validateAndSend({
        endPoint: updateProfileEndPoint,
        inputs: inputs,
        rules: updateProfileRules,
        formData: {
            name: name,
            username: inputs.username,
            avatar: imageUrl,
            contact: inputs.companyContact,
            address: inputs.companyAddress,
            location: {
                city: inputs.city,
                state: inputs.state,
                zipcode: inputs.zipcode
            },
            role: inputs.role,
            contact_person_name: inputs.contactPerson
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
};

export const getProfile = async(id) => {
    return validateAndSend({
        endPoint: getProfileEndPoint,
        emit: true
    })
}

export const getPublicUserProfile = async(id) => {
    return validateAndSend({
        endPoint: getPublicProfileEndPoint(id),
        emit: true
    })
}

export const updateProfilePassword = async (inputs) => {
    let entries = {
        currentPassword: inputs.password,
        newPassword: inputs.password2,
        comfirmPassword: inputs.password3,
    }
    return validateAndSend({
        endPoint: updatePasswordEndPoint,
        inputs: entries,
        rules: updatePasswordRules,
        formData: {
            old_password: entries.currentPassword,
            new_password: entries.newPassword,
            comfirm_password: entries.comfirmPassword
        },
        onSuccess: (response) => {
            set_session_helper(response);
        }
    });
};